import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  Spinner,
  Tabs,
  Tab,
  Card,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import {
  fetchCampaignDetail,
  updateGuestStatus,
  fetchAllCampaignDetail,
  fetchCampaignById,
} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
import "../../pages/eventsDetails/styleModule.css";
import { useNavigate, useParams } from "react-router-dom";
import SortImage from "../../../sort.svg";
import PaginationComponent from "../pagination/pagination";
import CommonLayout from "../../layouts/master/index";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import * as XLSX from "xlsx";
import { ArrowLeft } from "../../../constants/icons/icons";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import { RefreshCcw, ChevronLeft, ChevronRight } from "react-feather";

let ws;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CampaignDetails() {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const campaingDetailsData = useSelector(
    (state) => state.events?.campaingDetailsData
  );
  const total = useSelector((state) => state.events?.total);
  const totalPages = useSelector((state) => state.events?.totalPages);
  const campaingDetails = useSelector((state) => state.events?.campaingDetails);

  const [currentPage, setCurrentPage] = useState(0);
  const [limit] = useState(10);
  const [searchTerm] = useState("");
  const [documentUrl, setDocumentUrl] = useState(null);
  const [pdfTotalPages, setPdfTotalPages] = useState(0);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);

  const [activeTab, setActiveTab] = useState("list");
  const [isFailedSelected, setIsFailedSelected] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("guests");

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchDocument = useCallback(
    async (url) => {
      try {
        setLoading(true);
        const response = await axios.get(url, { responseType: "blob" });
        const blobUrl = URL.createObjectURL(response.data);

        setDocumentUrl(blobUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    },
    [setLoading]
  );

  const fetchCampaignDetailData = useCallback(() => {
    if (activeTab === "list") {
      setIsFailedSelected(false);
      setLoading(true);
      dispatch(
        fetchCampaignDetail({
          offset: currentPage,
          limit,
          search: searchTerm,
          is_test: activeSubTab === "guests" ? "false" : "true",
          id: id,
        })
      )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, currentPage, limit, searchTerm, id, activeSubTab, setLoading, activeTab]);

  const fetchCampaignByIdData = useCallback(() => {
    if (activeTab === "details") {
      setLoading(true);
      dispatch(fetchCampaignById({ id }))
        .then((response) => {
          const { campaingDetails } = response.payload;
          if (campaingDetails && campaingDetails?.new_template_url) {
            fetchDocument(campaingDetails.new_template_url);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, id, setLoading, fetchDocument, activeTab]);

  useEffect(() => {
    fetchCampaignDetailData();
  }, [fetchCampaignDetailData]);

  useEffect(() => {
    fetchCampaignByIdData();
  }, [fetchCampaignByIdData]);

  const handleStatusClick = (status) => {
    setLoading(true);
    setIsFailedSelected(status === "failed");
    const fetchParams = {
      offset: currentPage,
      limit,
      search: searchTerm,
      is_test: activeTab === "list" ? "false" : "true",
      id: id,
    };

    if (status !== "all") {
      fetchParams.status = status;
    }

    dispatch(fetchCampaignDetail(fetchParams))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);

    ws.onopen = () => {
      const wsId = "cmd";
      ws.send(JSON.stringify({ type: "REGISTER", id: wsId }));
      // setSock(ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { status, guest, reason } = data;
      dispatch(updateGuestStatus({ id: parseInt(guest.id), status, reason }));
      setSnackbarMessage(`Message to ${guest.mobile_number} ${status}`);
      setSnackbarVariant(status === "sent" ? "success" : "danger");
      setShowSnackbar(true);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      setTimeout(() => {
        ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);
      }, 1000);
    };

    return () => {
      ws.close();
    };
  }, [dispatch]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleBackClick = () => {
    navigate(
      campaingDetailsData?.data[0]?.client_id
        ? `/customers/${campaingDetailsData?.data[0]?.client_id}`
        : -1
    );
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
  };

  const handleExportClick = async () => {
    try {
      setLoading(true);
      const allDataResponse = await dispatch(
        fetchAllCampaignDetail({
          offset: currentPage,
          limit: campaingDetailsData?.total_recipients,
          search: searchTerm,
          is_test: "false",
          id: id,
        })
      ).unwrap();

      const dataToExport = allDataResponse?.campaingDetailsData?.data?.map(
        (event, index) => ({
          Name: event?.name,
          Mobile: event?.mobile_number,
          Date: new Date(event?.campaign.created_at).toLocaleString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          ScheduleDate: event?.campaign.scheduler_date
            ? moment(event?.campaign?.scheduler_date).format(
                "DD/MM/YYYY hh:mm A"
              )
            : "-",
          Status: event?.status !== "pending" ? event.status : "Pending",
          FailedReason: event?.failed_reason || "-",
          CreditsUsed: event?.credits,
          SentStatus: event?.whatsapp_sent
            ? new Date(event?.whatsapp_sent * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          DeliveryStatus: event?.whatsapp_deliver
            ? new Date(event?.whatsapp_deliver * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          SeenStatus: event?.whatsapp_seen
            ? new Date(event?.whatsapp_seen * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
        })
      );

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign Details");

      XLSX.writeFile(workbook, "campaign_details.xlsx");
      setLoading(false);
    } catch (error) {
      console.error("Error exporting data: ", error);
      setLoading(false);
    }
  };

  console.log("campaingDetailsData?.data[0]?.campaign?.template_url : ", campaingDetailsData);

  const handleResendFailedClick = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/resend-failed`,
        {
          clientId: campaingDetailsData?.data[0]?.client_id,
          wsId: "cmd",
          campaignId: parseInt(id),
          fileUrl: campaingDetailsData?.data[0]?.campaign?.template_type !== "text"? `${process.env.REACT_APP_API_URL}/storage/${campaingDetailsData?.data[0]?.campaign?.template_url}` : null,
          companyId: campaingDetailsData?.data[0]?.company_id,
        }
      );
      setSnackbarMessage("Resending failed messages started successfully");
      setSnackbarVariant("success");
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage("Failed to start resending messages");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfTotalPages(numPages);
    setPdfCurrentPage(1);
  };

  const goToNextPage = () => {
    setPdfCurrentPage((prevPage) => Math.min(prevPage + 1, pdfTotalPages));
  };

  const goToPreviousPage = () => {
    setPdfCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSubTabSelect = (key) => {
    setActiveSubTab(key);
    setCurrentPage(0);
  };

  return (
    <CommonLayout>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          {snackbarMessage}
        </Toast.Body>
      </Toast>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex align-items-center gap-2 primary-pg-title">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                  onClick={() => handleBackClick()}
                >
                  <ArrowLeft
                    size={24}
                    color="#FFFFFF"
                    weight="bold"
                    className="cursor-pointer"
                  />
                </div>

                <h3 className="mb-0 fw-semibold text-white pg-title fw-medium">
                  Campaign Details
                </h3>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <Tabs
              activeKey={activeTab}
              className="pb-0 table-tabs-items"
              id="uncontrolled-tab-example"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="list"
                title={
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">List</p>
                    </div>
                  </>
                }
              >
                <div className="tablecontainer">
                  <div
                    className={`mb-3 d-flex ${
                      isFailedSelected
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {isFailedSelected && (
                      <Button
                        variant="danger"
                        onClick={handleResendFailedClick}
                        disabled={campaingDetailsData?.data?.length === 0}
                      >
                        Resend All Failed
                      </Button>
                    )}
                    <div className=" ">
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={() => handleExportClick()}
                        disabled={campaingDetailsData.total_non_tested === 0? true : false}
                      >
                        Export
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => handleRefreshClick()}
                        className="me-2"
                      >
                        <RefreshCcw size={15} className="align-middle me-0" />
                      </Button>
                    </div>
                  </div>

                  <Row className="my-5 g-4 cerdit-logs-content">
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card h-100"
                        onClick={() => handleStatusClick("all")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="mb-0 text-capitalize">
                              Total Recipients
                            </p>
                            <span>
                              {activeSubTab === 'guests'? campaingDetailsData?.total_non_tested: campaingDetailsData?.total_tested || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("pending")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">In Queue</p>
                            <span>
                              {campaingDetailsData?.total_pending || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("sent")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Sent</p>
                            <span>{campaingDetailsData?.total_sent || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("delivered")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Delivered</p>
                            <span>
                              {campaingDetailsData?.total_delivered || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("seen")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Seen</p>
                            <span>{campaingDetailsData?.total_read || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("failed")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Failed</p>
                            <span>
                              {campaingDetailsData?.total_failed || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("invalid")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Invalid</p>
                            <span>
                              {campaingDetailsData?.total_invalid || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="table-tabs">
                    <Tabs
                      activeKey={activeSubTab}
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-subtab-example"
                      onSelect={handleSubTabSelect}
                    >
                      <Tab
                        eventKey="guests"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Guests</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="test"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Test</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </Row>

                  <div className=" overflow-auto">
                    <Table className="text-nowrap data_list text-start ">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Id</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="">Name</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Mobile</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Date</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Schedule Date</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Failed Reason</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Credits Used</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Sent Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Delivery Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Seen Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaingDetailsData?.data?.length === 0 ? (
                          <tr>
                            <td
                              className="mt-5 fw-bold text-center fs-5"
                              colSpan="11"
                            >
                              No data available
                            </td>
                          </tr>
                        ) : (
                          campaingDetailsData?.data?.map((event, index) => (
                            <tr key={index}>
                              <td>{index + 1 + currentPage * 10}</td>
                              <td className="text-capitalize">{event?.name}</td>
                              <td>{event?.mobile_number}</td>
                              <td>
                                {new Date(
                                  event?.campaign.created_at
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </td>
                              <td>
                                {event?.campaign.scheduler_date
                                  ? moment(
                                      event?.campaign?.scheduler_date
                                    ).format("DD/MM/YYYY hh:mm A")
                                  : "-"}
                              </td>
                              <td className="text-capitalize">
                                {event?.status !== "pending" ? (
                                  event.status
                                ) : (
                                  <Spinner animation="border" size="sm" />
                                )}
                              </td>
                              <td className="text-capitalize">{event?.failed_reason || "-"}</td>
                              <td>{event?.credits}</td>
                              <td>
                                {event?.whatsapp_sent
                                  ? new Date(
                                      event?.whatsapp_sent * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                              <td>
                                {event?.whatsapp_deliver
                                  ? new Date(
                                      event?.whatsapp_deliver * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                              <td>
                                {event?.whatsapp_seen
                                  ? new Date(
                                      event?.whatsapp_seen * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                    {totalPages > 1 ? (
                      <PaginationComponent
                        totalItems={total}
                        itemsPerPage={limit}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    ) : null}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="details"
                title={
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Details</p>
                    </div>
                  </>
                }
              >
                {campaingDetails ? (
                  <Card className="mt-5">
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <ListGroup variant="flush">
                            <ListGroupItem>
                              <strong>Campaign Name:</strong>{" "}
                              {campaingDetails.name}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Message:</strong>{" "}
                              {campaingDetails.message}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Status:</strong> {campaingDetails.status}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Created At:</strong>{" "}
                              {moment(campaingDetails.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Updated At:</strong>{" "}
                              {moment(campaingDetails.updated_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Customer:</strong>{" "}
                              {campaingDetails.client_event?.name}
                            </ListGroupItem>
                          </ListGroup>
                        </Col>
                        <Col md={6}>
                          <h5 className="mt-4">Document</h5>
                          <div className="document-viewer mt-3">
                            {documentUrl ? (
                              campaingDetails?.template_type === "pdf" ? (
                                <>
                                  <Document
                                    file={documentUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) =>
                                      console.log(
                                        "Error while loading document:",
                                        error
                                      )
                                    }
                                    onSourceError={(error) =>
                                      console.log(
                                        "Error while fetching document source:",
                                        error
                                      )
                                    }
                                    className="d-flex justify-content-center"
                                  >
                                    <Page
                                      pageNumber={pdfCurrentPage}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                    />
                                  </Document>
                                  <div className="d-flex justify-content-center mb-3">
                                    <Button
                                      variant="outline-primary"
                                      className="me-2"
                                      onClick={goToPreviousPage}
                                      disabled={pdfCurrentPage === 1}
                                    >
                                      <ChevronLeft />
                                    </Button>
                                    <div className="d-flex align-items-center">
                                      Page {pdfCurrentPage} of {pdfTotalPages}
                                    </div>
                                    <Button
                                      variant="outline-primary"
                                      className="ms-2"
                                      onClick={goToNextPage}
                                      disabled={
                                        pdfCurrentPage === pdfTotalPages
                                      }
                                    >
                                      <ChevronRight />
                                    </Button>
                                  </div>
                                </>
                              ) : campaingDetails?.template_type?.startsWith(
                                  "image"
                                ) ? (
                                <img
                                  src={documentUrl}
                                  alt="Campaign Document"
                                  style={{ maxWidth: "100%" }}
                                />
                              ) : (
                                <p>Unsupported document type</p>
                              )
                            ) : (
                              <p>No document available</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <Spinner animation="border" size="sm" />
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </CommonLayout>
  );
}
