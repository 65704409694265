import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Toast,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormLabel,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { addEvent, fetchEvents } from "../../../store/eventsSlice";
import * as Yup from "yup";
// import axios from "axios";

export default function Add({
  show,
  handleClose,
  currentPage,
  setCurrentPage,
  searchTerm,
  limit,
  id,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [qrCode, setQrCode] = useState(null);
  // const [ws, setWs] = useState(null);
  // const [isScanSuccessful, setIsScanSuccessful] = useState(false); // State to track if the QR code scan was successful
  const dispatch = useDispatch();
  // console.log('WS',ws);

  const initialEventState = {
    name: "",
    contact_number: "",
    email: "",
    address: "",
  };

  const eventValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    email: Yup.string().email("Invalid email address").nullable(),
    address: Yup.string().nullable(),
  });

  const handleModalClose = useCallback(() => {
    // setQrCode('')
    handleClose();
  }, [handleClose]);

  // useEffect(() => {
  //   const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  //   socket.onopen = () => {
  //     console.log('WebSocket Client Connected');
  //     const wsId = `ws_${id}`; // Generate or fetch a unique WebSocket ID
  //     socket.send(JSON.stringify({ type: 'REGISTER', id: wsId }));
  //     setWs(socket);
  //   };

  //   socket.onmessage = (message) => {
  //     const data = JSON.parse(message.data);
  //     if (data.status === 'connected') {
  //       setSnackbarMessage("WhatsApp connected successfully");
  //       setSnackbarVariant("success");
  //       setShowSnackbar(true);
  //       setIsScanSuccessful(true); // Enable the "Add" button on successful scan
  //     } else if (data.status === 'disconnected') {
  //       setSnackbarMessage("Failed to connect to WhatsApp");
  //       setSnackbarVariant("danger");
  //       setShowSnackbar(true);
  //       setIsScanSuccessful(false); // Disable the "Add" button on failure
  //     }
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket Client Disconnected');
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, [id]);

  // const handleConnect = async (mobile_number) => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/connect`, {
  //       clientId: mobile_number,
  //       wsId: `ws_${id}`, // Use the same WebSocket ID
  //       userId: id,
  //     });
  //     setQrCode(response.data.qrString);
  //   } catch (error) {
  //     console.error("Error connecting to WhatsApp:", error);
  //     setSnackbarMessage("Failed to connect to WhatsApp");
  //     setSnackbarVariant("danger");
  //     setShowSnackbar(true);
  //   }
  // };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal show={show} size="md" className="" centered>
        <ModalHeader className=" ">
          <h3 className="text-primary mb-0">New Customer</h3>
        </ModalHeader>
        <Formik
          initialValues={initialEventState}
          validationSchema={eventValidation}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
            // if (!isScanSuccessful) {
            //   setSnackbarMessage("Please scan the QR code first");
            //   setSnackbarVariant("danger");
            //   setShowSnackbar(true);
            //   setSubmitting(false);
            //   return;
            // }
            // setSubmitting(true);
            setIsSubmitting(true)

            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("contact_number", values.contact_number);
            formData.append("email", values.email);
            formData.append("address", values.address);

            dispatch(addEvent(formData))
              .then((res) => {
                if (res.payload.success) {
                  // setCurrentPage()
                  dispatch(
                    fetchEvents({
                      offset: currentPage,
                      limit,
                      search: searchTerm,
                      id: id,
                    })
                  );
                  setSnackbarMessage(res.payload.message);
                  setSnackbarVariant("success");
                  setShowSnackbar(true);
                  resetForm();
                  handleModalClose();
                  setIsSubmitting(false);
                } else {
                  setSnackbarMessage(res.payload.message);
                  setErrors(res.payload.data);
                  setSnackbarVariant("danger");
                  setShowSnackbar(true);
                  setIsSubmitting(false);
                }
              })
              .catch((error) => {
                setSnackbarMessage("Failed to add customer: " + error.message);
                setSnackbarVariant("danger");
                setShowSnackbar(true);
                setIsSubmitting(false);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form encType="multipart/form-data">
              <ModalBody>

              <FormLabel htmlFor="name">Name</FormLabel>
                <Field
                  name="name"
                  type="text"
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                  placeholder="Enter name"
                  onChange={handleChange}
                />
                {errors.name && touched.name ? (
                  <div className="invalid-feedback">{errors.name}</div>
                ) : null}
                <br />

                <FormLabel htmlFor="contact_number">Contact Number</FormLabel>
                <Field
                  name="contact_number"
                  type="text"
                  className={`form-control ${
                    errors.contact_number && touched.contact_number
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter contact number"
                  onChange={handleChange}
                />
                {errors.contact_number && touched.contact_number ? (
                  <div className="invalid-feedback">
                    {errors.contact_number}
                  </div>
                ) : null}
                <br />

                <FormLabel htmlFor="email">Email</FormLabel>
                <Field
                  name="email"
                  type="text"
                  className={`form-control ${
                    errors.email && touched.email
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Email"
                  onChange={handleChange}
                />
                {errors.email && touched.email ? (
                  <div className="invalid-feedback">
                    {errors.email}
                  </div>
                ) : null}
                <br />

                <FormLabel htmlFor="address">Address</FormLabel>
                <Field
                  name="address"
                  type="text"
                  className={`form-control ${
                    errors.address && touched.address
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Address"
                  onChange={handleChange}
                />

                {/* {qrCode && (
                  <div className="text-center">
                  <img src={qrCode} alt="WhatsApp QR Code" />
                  <p>Please open WhatsApp and scan the QR code</p>
                  </div>
                  )} */}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-center">
                {/* <Button
                  variant="secondary"
                  onClick={() => handleConnect(values.mobile_number)}
                  disabled={isSubmitting}
                  >
                  Connect
                  </Button> */}
                {/* {console.log("isSubmitting: ", isSubmitting)} */}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting} // Enable only after successful scan
                >
                  {isSubmitting? "Adding...": "Add"}
                </Button>
                <Button
                  className="mx-25"
                  color="danger"
                  variant="secondary"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
