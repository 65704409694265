import React from "react";
import { Table } from "reactstrap";
import "./style.scss";
import { Image } from "react-bootstrap";

const ReusableTable = ({ tableData, tableKey, tableScrollClass }) => {
  let defaultTableKey = [
    "name",
    "number",
    "var1",
    "var2",
    "var3",
    "var4",
    "var5",
  ];

  const tableKey1 = new Set(
    tableKey && tableKey.length !== 0 ? tableKey : defaultTableKey
  );

  let tableData1 = [...tableData];

  return (
    <div className="tablescroll_imp">
      <Table
        responsive
        className={`${tableData1.length > 0 && tableScrollClass} data_list`}
      >
        <thead className="text-light">
          <tr className="border-0">
            {tableKey1 &&
              Array.from(tableKey1.values()).map((rows, index) => {
                return (
                  <th scope="col" key={index} className="" >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{rows}</span>
                      <Image src="/images/modal-icon/sort-icon.svg" alt="sorting" width={16} height={16} />
                    </div>
                  </th>
                );
              })}
              {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {tableData1 &&
            tableData1.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    {Array.from(tableKey1.values()).map((item, i) => {
                      return (
                        <td key={i} className="text-start">
                          <p
                            className={
                              item === "var1" ||
                              item === "var2" ||
                              item === "var3" ||
                              item === "var4" ||
                              item === "var5"
                                ? "text_table_10"
                                : null
                            }
                          >
                            {data[item]}
                          </p>
                        </td>
                        
                      );
                    })}
                    {/* <td className="text-end">
                      <Image src="/images/modal-icon/action-icon.svg" alt="action-btn" width={20} height={20} className=""/>
                    </td> */}
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ReusableTable;
