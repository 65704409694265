import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  // Progress,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import classNames from "classnames";
import {
  // Calendar,
  // ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Download,
  Edit,
  Plus,
  RefreshCcw,
  // Send,
  Trash2,
} from "react-feather";
import { PDFDocument, rgb } from "pdf-lib";
import {
  // Document,
  // Page,
  pdfjs,
} from "react-pdf";
import { Image, Layer, Stage, Text } from "react-konva";
import fontkit from "@pdf-lib/fontkit";
import useImage from "use-image";
import TimesNewRoman from "../Fonts/timesNewRoman.ttf";
// import { fontStyleOptions } from "../Fonts/FontStyle";
import { fontStyleOptions } from "../Fonts/FontStyle";
// import {}
import {
  // useLocation,
  useParams,
  // useNavigate
} from "react-router-dom";
import {
  convertBase64ToFile,
  // dateTimeToUCT,
  pdfToBase64File,
} from "../common/Common";
import "../styleModule.css";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
// import { Toast } from "react-bootstrap";

// import { sendLastMessage, sendMessage } from "../../../../@core/api/eventApi";
// import ComponentSpinner from "../../../../@core/components/spinner/Loading-spinner";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { notification } from "../../../../@core/constants/notification";
// import { notification } from "./common/notification";

const URLImage = ({ image }) => {
  const [img] = useImage(image);
  return <Image image={img} x={0} y={0} />;
};

const EditPdf = ({
  // stepper,
  tableData,
  csvKeys,
  totalPages,
  pagesUrl,
  file,
  fileType,
  messageData,
  formData,
  setFormData,
  setPos,
  pos,
  inputdata,
  formError,
  campaingDetails,
  isTestOpen,
  setIsTestOpen,
  handleTestClick,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { clientId, id } = useParams();
  console.log("CamID : ", id, "Client iD:: ", clientId);

  // const navigate = useNavigate();
  const stageRef = useRef(null);
  // const { state } = useLocation();
  // const MySwal = withReactContent(Swal);

  const [currentPage, setCurrentPage] = useState(1);
  const [optionsVisible, setOptionsVisible] = useState({
    visible: false,
    key: -1,
  });
  // const [isTestOpen, setIsTestOpen] = useState(false);
  const [testMobile, setTestMobile] = useState("");
  // const [sendMessageLoading, setSendMessageLoading] = useState(false);
  // const [
  //   messageProgress,
  //   // setMessageProgress
  // ] = useState(0);
  // const [testProgress, setTestProgress] = useState(false);
  const [messagePreview, setMessagePreview] = useState("");

  const expiry_date = useSelector((state) => state.auth.user.expiry_date);
  const company_id = useSelector((state) => state.auth.user.company_id);

  const [modalSchedule, setModalSchedule] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("success");

  const toggleSchedule = () => setModalSchedule(!modalSchedule);

  const toggleTestModal = () => {
    setTestMobile("");
    setIsSubmitting(false);
    setIsTestOpen(!isTestOpen);
  };
  const toggleOptionVisible = (key) =>
    setOptionsVisible(
      optionsVisible?.visible === true
        ? { visible: false, key: -1 }
        : { visible: true, key: key }
    );

  // function onDocumentLoadSuccess({ numPages }) {
  //   console.log("numpagessss", numPages);
  // }

  useEffect(() => {
    let newMessage = messageData;
    csvKeys.forEach((item) => {
      newMessage = newMessage.replaceAll(
        `[[${item}]]`,
        tableData[0][item].trim()
      );
    });
    setMessagePreview(newMessage);
  }, [messageData, csvKeys, tableData]);

  // const sendMessageData = async () => {
  //   setSendMessageLoading(true);
  //   let status = false;
  //   for (let j = 0; j < tableData.length; j++) {
  //     let media = [];
  //     if (file) {
  //       const pdfBytes = await changeFont(tableData[j]);
  //       if (fileType === "application/pdf") {
  //         media = new File([pdfBytes], `${tableData[j].name}.pdf`, {
  //           type: "application/pdf;charset=utf-8",
  //         });
  //       } else {
  //         const dataURL = await pdfToBase64File(pdfBytes, fileType);
  //         media = await convertBase64ToFile(dataURL);
  //       }
  //     }

  //     let newMessage = messageData;
  //     csvKeys.forEach((item) => {
  //       newMessage = newMessage.replaceAll(
  //         `[[${item}]]`,
  //         tableData[j][item].replaceAll(/;/g, ",").trim()
  //       );
  //     });

  //     let utcSchedule;
  //     var dateObj = new Date(formData?.schedule);

  //     formData?.schedule == null
  //       ? (utcSchedule = null)
  //       : (utcSchedule = dateTimeToUCT(dateObj));

  //     let request = new FormData();
  //     request.append("connectionId", state.connectionId);
  //     request.append("campaign_id", state?.campaign_id);
  //     request.append("message", JSON.stringify(newMessage));
  //     request.append(
  //       "delay",
  //       formData?.delaySecond ? formData?.delaySecond : 0
  //     );
  //     request.append("schedule", utcSchedule);
  //     request.append("numbers", tableData[j].number);
  //     request.append("receiver_name", tableData[j].name);
  //     request.append("total_message", tableData.length);
  //     request.append(
  //       "file_name",
  //       `${tableData[j].name}${
  //         fileType === "application/pdf"
  //           ? ".pdf"
  //           : fileType === "image/png"
  //           ? ".png"
  //           : ".jpeg"
  //       }`
  //     );
  //     request.append(
  //       "caption",
  //       formData?.captionData ? formData?.captionData : ""
  //     );
  //     request.append("media", media);

  //   //   let response = await sendMessage(request);
  //     setMessageProgress(j + 1);
  //   //   if (response?.status === 0) {
  //   //     toggleTestModal();
  //   //     MySwal.fire({
  //   //       title: response?.description,
  //   //       icon: "warning",
  //   //       confirmButtonText: "ok",
  //   //       customClass: {
  //   //         confirmButton: "btn btn-primary",
  //   //         cancelButton: "btn btn-outline-danger ms-1",
  //   //       },
  //   //       buttonsStyling: false,
  //   //     }).then(() => {
  //   //       if (response?.description === "insufficient balance") {
  //   //         navigate("/package");
  //   //       } else {
  //   //         navigate("/add-campaign", {
  //   //           state: { connectionId: state.connectionId },
  //   //         });
  //   //       }
  //   //     });
  //   //     status = false;
  //   //     break;
  //   //   } else {
  //   //     status = true;
  //   //   }
  //   }
  //   if (status === true) {
  //     notification({
  //       type: "success",
  //       title: "Event start Successfully",
  //     });
  //     setSendMessageLoading(false);
  //     navigate("/add-campaign", {
  //       state: { connectionId: state.connectionId },
  //     });
  //   //   let response = await sendLastMessage({
  //   //     length: tableData.length,
  //   //     connectionId: state.connectionId,
  //   //     campaign_id: state?.campaign_id,
  //   //   });
  //     setSendMessageLoading(false);
  //   //   if (response?.status === 0)
  //       // notification({
  //       //   type: "error",
  //       //   title: response?.description,
  //       // });
  //   }
  // };

  // const handleTestClick = async () => {
  //   console.log("campaingDetails?.customer?.credits : ", campaingDetails?.customer?.credits);
  //   if(campaingDetails?.customer?.credits <= tableData?.length){
  //     setSnackbarMessage("Not have enough credits.");
  //     setSnackbarVariant("danger");
  //     setShowSnackbar(true);
  //     return
  //   }
  //   setIsTestOpen(true)
  // }

  const sendTestMessage = async () => {
    if (!testMobile) {
      //   notification({
      //     type: "error",
      //     title: "Please enter Mobile number",
      //   });
    } else {
      setIsSubmitting(true);
      // setSendMessageLoading(true);
      // setTestProgress(true);
      let media = [];
      if (file) {
        const pdfBytes = await changeFont(tableData[0]);
        if (fileType === "application/pdf") {
          media = new File([pdfBytes], `${tableData[0].name}.pdf`, {
            type: "application/pdf;charset=utf-8",
          });
        } else {
          const dataURL = await pdfToBase64File(pdfBytes, fileType);
          media = await convertBase64ToFile(dataURL);
        }
      }
      let newMessage = messageData;
      csvKeys.forEach((item) => {
        newMessage = newMessage.replaceAll(
          `[[${item}]]`,
          tableData[0][item].replaceAll(/;/g, ",").trim()
        );
      });
      let request = new FormData();
      // request.append("connectionId", state.connectionId);
      request.append("campaign_id", id);
      request.append("clientId", clientId);
      request.append("company_id", company_id);
      request.append("message", JSON.stringify(newMessage));
      request.append(
        "delay",
        formData?.delaySecond ? formData?.delaySecond : 0
      );
      // request.append("schedule", null);
      request.append("numbers", testMobile);
      request.append("name", "Test Message");
      // request.append("total_message", 1);
      request.append(
        "file_name",
        `Test${
          fileType === "application/pdf"
            ? ".pdf"
            : fileType === "image/png"
            ? ".png"
            : ".jpeg"
        }`
      );
      // request.append(
      //   "caption",
      //   formData?.captionData ? formData?.captionData : ""
      // );
      request.append("media", media);

      await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/send-test-message`,
        request
      );

      //   let response = await sendMessage(request);
      setTestMobile("");
      //   if (response?.status === 0) {
      //     toggleTestModal();
      //     MySwal.fire({
      //       title: response?.description,
      //       icon: "warning",
      //       confirmButtonText: "ok",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //         cancelButton: "btn btn-outline-danger ms-1",
      //       },
      //       buttonsStyling: false,
      //     }).then(() => {
      //       if (response?.description === "insufficient balance") {
      //         navigate("/package");
      //       }
      //     });
      //     setSendMessageLoading(false);
      //   } else {
      //     setTestProgress(false);
      //     setSendMessageLoading(false);
      //     let response1 = await sendLastMessage({
      //       length: 0,
      //       connectionId: state.connectionId,
      //       campaign_id: state?.campaign_id,
      //     });
      //     setSendMessageLoading(false);
      //     // notification({
      //     //   type: response1.status == 1 ? "success" : "error",
      //     //   title: response?.description,
      //     // });
      //   }
      setIsSubmitting(false);
      // setSendMessageLoading(false);
      // setTestProgress(false);
      toggleTestModal();
      setTestMobile("");
    }
  };

  const changeFont = async (data) => {
    const existingPdfBytes = await fetch(URL.createObjectURL(file)).then(
      (res) => res.arrayBuffer()
    );
    const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDocs1.getPages();
    await pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );
      // let font = await fontkit.create(fontBytes);
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);
    }
    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const {
        // width,
        height,
      } = page.getSize();
      // const textWidth = embeddedFonts[index]?.widthOfTextAtSize(
      //   data[key],
      //   pos[key]?.size
      // );
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  const downloadPDF = async () => {
    const link = document.createElement("a");
    const pdfBytes = await changeFont(tableData[0]);
    let file = new File([pdfBytes], "test-Certificate.pdf", {
      type: "application/pdf;charset=utf-8",
    });
    if (fileType === "application/pdf") {
      const encodedUri = window.URL.createObjectURL(file);
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${tableData[0].name}.pdf`);
    } else {
      const dataURL = await pdfToBase64File(
        window.URL.createObjectURL(file),
        fileType
      );
      link.setAttribute("href", dataURL);
      link.setAttribute(
        "download",
        `${tableData[0].name}${fileType === "image/png" ? ".png" : ".jpeg"}`
      );
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDragEnd = (e, key) => {
    const textNode = e.target;
    setPos({
      ...pos,
      [key]: {
        ...pos[`${key}`],
        x: textNode.x(),
        y: textNode.y(),
      },
    });
  };

  const componentToHex = (c) => {
    var hex = c?.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rgbToHex = (color) =>
    "#" +
    componentToHex(color[0]) +
    componentToHex(color[1]) +
    componentToHex(color[2]);

  return (
    <Fragment>
      <>
        {file ? (
          <Row>
            <Col xl="12" className="mb-25 ">
              
              <Row className="pt-5">
                <Col md="3" className="border_rightcol pt-2">
                  {/* <div className="d-inline-block">
                    <Button
                      className="d-flex align-items-center justify-content-center w-100"
                      color="primary"
                      onClick={toggleSchedule}
                      onChange={setFormData}
                    >
                      <Calendar className="me-1" />
                      Schedule Send
                    </Button>
                  </div> */}
                  {/* <div className="py-3 text-start">
                    <p className="my-50 text-primary fs-5">
                      {formData?.schedule &&
                        moment(formData?.schedule).format("DD-MM-YYYY hh:mm A")}
                    </p>
                  </div> */}
                  <div className="d-flex justify-content-between align-items-center fw-bold ps-1 mb-2">
                    <h4>Add Values</h4>
                    <div className="d-flex justify-content-end">
                      <Button
                        id="refreshBtn"
                        className="btn-icon"
                        color="primary"
                        size="sm"
                        outline
                        style={{ margin: "5px" }}
                        onClick={async () => {
                          setPos({});
                          setCurrentPage(1);
                        }}
                      >
                        <RefreshCcw size={15} className="align-middle me-0" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target="refreshBtn"
                        >
                          Refresh
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        id="downloadBtn"
                        className="btn-icon"
                        color="primary"
                        size="sm"
                        outline
                        style={{ margin: "5px" }}
                        onClick={() => downloadPDF()}
                      >
                        <Download size={15} className="align-middle ms-0" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target="downloadBtn"
                        >
                          Download
                        </UncontrolledTooltip>
                      </Button>
                    </div>
                  </div>
                  <div className="edit_add_card mt-3">
                    <div className="cardbody_data">
                      {tableData &&
                        tableData?.length > 0 &&
                        Object.keys(tableData[0]).map((data, index) => {
                          return (
                            index !== 1 && (
                              <>
                                <div
                                  key={index}
                                  className={classNames(
                                    "d-flex justify-content-start align-items-center",
                                    {
                                      "mt-1": index === 0,
                                      // eslint-disable-next-line no-dupe-keys
                                      "mt-1": index !== 0,
                                    }
                                  )}
                                >
                                  <Col md="8">
                                    <p
                                      className="mb-0 text-start"
                                      style={{
                                        wordBreak: "break-word",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {(tableData[0][data] || data)?.replaceAll(
                                        /;/g,
                                        ","
                                      )}
                                    </p>
                                  </Col>
                                  <Col
                                    md="4"
                                    className="d-flex justify-content-end align-items-center"
                                  >
                                    {!pos[data] && (
                                      <Button
                                        id="addVariable"
                                        className="btn-icon"
                                        color="primary"
                                        size="sm"
                                        outline
                                        style={{
                                          margin: "5px",
                                          padding: "3px",
                                        }}
                                        onClick={async () => {
                                          setPos({
                                            ...pos,
                                            [data]: {
                                              text: (
                                                tableData[0][data] || data
                                              ).replaceAll(/;/g, ","),
                                              page: currentPage,
                                              x:
                                                pagesUrl[currentPage - 1]
                                                  ?.width / 2,
                                              y:
                                                pagesUrl[currentPage - 1]
                                                  ?.height / 2,
                                              color: [0, 0, 0],
                                              size: 18,
                                              fontStyle: "TimesNewRoman",
                                              fontFile: TimesNewRoman,
                                            },
                                          });
                                        }}
                                      >
                                        <Plus size={15} />
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="addVariable"
                                        >
                                          Add
                                        </UncontrolledTooltip>
                                      </Button>
                                    )}
                                    {pos[data] && (
                                      <>
                                        <Button
                                          id="removeVariable"
                                          className="btn-icon"
                                          color="primary"
                                          size="sm"
                                          outline
                                          style={{
                                            margin: "5px",
                                            padding: "3px",
                                          }}
                                          onClick={async () => {
                                            setPos((current) => {
                                              const copy = { ...current };
                                              delete copy[data];
                                              return copy;
                                            });
                                          }}
                                        >
                                          <Trash2 size={15} />
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="removeVariable"
                                          >
                                            Remove
                                          </UncontrolledTooltip>
                                        </Button>
                                        <ButtonDropdown
                                          className="dropdown-icon-wrapper"
                                          isOpen={
                                            optionsVisible &&
                                            optionsVisible?.visible === true &&
                                            optionsVisible?.key === data
                                          }
                                          toggle={() =>
                                            toggleOptionVisible(data)
                                          }
                                          style={{
                                            width: "max-content !important",
                                            minWidth: "max-content !important",
                                          }}
                                        >
                                          <DropdownToggle
                                            id="edit"
                                            color="primary"
                                            caret
                                            outline
                                            className="btn-icon btn-sm d-flex align-items-center"
                                            style={{
                                              margin: "5px",
                                              padding: "3px",
                                            }}
                                          >
                                            <Edit size={16} />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target="edit"
                                            >
                                              Edit
                                            </UncontrolledTooltip>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            end
                                            style={{
                                              width: "max-content !important",
                                              minWidth: "",
                                            }}
                                          >
                                            <div
                                              className="px-2 py-2"
                                              style={{ width: "max-content" }}
                                            >
                                              <div className="mb-2">
                                                <Label
                                                  className="form-label"
                                                  for="font"
                                                >
                                                  Select Font
                                                </Label>
                                                <select
                                                  className="form-select add_fontstyle"
                                                  id="font"
                                                  aria-label="Select Font"
                                                  value={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.value
                                                  }
                                                  label={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.label
                                                  }
                                                  style={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.style
                                                  }
                                                  onChange={async (e) => {
                                                    setPos({
                                                      ...pos,
                                                      [data]: {
                                                        ...pos[`${data}`],
                                                        fontStyle:
                                                          e.target.value,
                                                        fontFile:
                                                          fontStyleOptions.filter(
                                                            (option) =>
                                                              option.value ===
                                                              e.target?.value
                                                          )[0]?.file,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <option
                                                    defaultValue="Select Font"
                                                    disabled
                                                  >
                                                    Select Font :
                                                  </option>
                                                  {fontStyleOptions.map(
                                                    (option) => (
                                                      <option
                                                        value={option.value}
                                                        style={option.style}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                              <div className="my-3 d-flex align-items-center color-picker-wrapper">
                                                <Label
                                                  className="form-label m-0"
                                                  for="color"
                                                >
                                                  Select Color :
                                                </Label>
                                                <input
                                                  className="p-0 border-0 ms-2"
                                                  type="color"
                                                  id="color"
                                                  name="color"
                                                  value={
                                                    pos[`${data}`]?.color
                                                      ? rgbToHex(
                                                          pos[`${data}`]?.color
                                                        )
                                                      : [0, 0, 0]
                                                  }
                                                  onChange={async (e) => {
                                                    let hexValue =
                                                      e?.target?.value;
                                                    let rgbValue = [
                                                      ("0x" +
                                                        hexValue[1] +
                                                        hexValue[2]) |
                                                        0,
                                                      ("0x" +
                                                        hexValue[3] +
                                                        hexValue[4]) |
                                                        0,
                                                      ("0x" +
                                                        hexValue[5] +
                                                        hexValue[6]) |
                                                        0,
                                                    ];
                                                    setPos({
                                                      ...pos,
                                                      [data]: {
                                                        ...pos[`${data}`],
                                                        color: rgbValue,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="mb-2">
                                                <Label
                                                  className="form-label"
                                                  for="size"
                                                >
                                                  Font Size :
                                                </Label>
                                                <Input
                                                  className=""
                                                  placeholder="Enter Font Size"
                                                  type="number"
                                                  id="size"
                                                  name="size"
                                                  max={10000}
                                                  min={5}
                                                  onWheel={(e) =>
                                                    e.currentTarget.blur()
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.select()
                                                  }
                                                  value={pos[`${data}`]?.size}
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(
                                                        e.target.value || 0
                                                      ) >= 0
                                                    ) {
                                                      setPos({
                                                        ...pos,
                                                        [data]: {
                                                          ...pos[`${data}`],
                                                          size: parseInt(
                                                            e?.target?.value
                                                              .replace(
                                                                /[^0-9]/g,
                                                                ""
                                                              )
                                                              .replaceAll(
                                                                RegExp(
                                                                  "^0+(?!$)",
                                                                  "g"
                                                                ),
                                                                ""
                                                              )
                                                          ),
                                                        },
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </DropdownMenu>
                                        </ButtonDropdown>
                                      </>
                                    )}
                                  </Col>
                                </div>
                              </>
                            )
                          );
                        })}
                    </div>
                  </div>
                </Col>
                <Col
                  md="9"
                  className="d-flex align-items-center justify-content-center pt-2"
                >
                  {currentPage > 1 && (
                    <div>
                      <ChevronLeft
                        color="#624bff"
                        size={35}
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                      />
                    </div>
                  )}
                  {file && (
                    <div
                      className="align-items-center"
                      style={{
                        overflow: "auto",
                        maxHeight: 500,
                        maxWidth: 1000,
                      }}
                    >
                      <Stage
                        id="stage"
                        ref={stageRef}
                        width={pagesUrl[currentPage - 1]?.width}
                        height={pagesUrl[currentPage - 1]?.height}
                      >
                        <Layer>
                          <URLImage image={pagesUrl[currentPage - 1]?.base64} />
                          {Object.keys(pos).map((key) => {
                            if (pos[key]?.page === currentPage) {
                              return (
                                <Text
                                  text={pos[key]?.text}
                                  x={pos[key]?.x}
                                  y={pos[key]?.y}
                                  draggable
                                  onDragEnd={(e) => {
                                    handleDragEnd(e, key);
                                  }}
                                  fill={`rgb(${pos[key]?.color[0]} ${pos[key]?.color[1]} ${pos[key]?.color[2]})`}
                                  fontSize={pos[key]?.size}
                                  fontFamily={pos[key]?.fontStyle}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Layer>
                      </Stage>
                      {/* <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        }
                      >
                        <Page
                          loading=""
                          className="react-pdf__Page__canvas"
                          key={`page_${currentPage}`}
                          pageNumber={currentPage}
                        />
                      </Document> */}
                      {totalPages > 1 && (
                        <div
                          align="center"
                          style={{ color: "#624bff", background: "white" }}
                        >
                          {currentPage} of {totalPages}
                        </div>
                      )}
                    </div>
                  )}
                  {totalPages === 1 ||
                    (currentPage < totalPages && (
                      <div>
                        <ChevronRight
                          size={35}
                          color="#624bff"
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                        />
                      </div>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Card>
            <CardHeader className="header-table mb-75">
              <div className=" fw-bold text-light">Message Preview</div>
            </CardHeader>
            <CardBody>
              <pre>{messagePreview}</pre>
            </CardBody>
          </Card>
        )}
        <div className="d-flex justify-content-between">
          {/* <Button
            color="secondary"
            className="btn-prev"
            outline
            onClick={() => stepper.previous()}
          >
            <ArrowLeft
              size={14}
              className="align-middle me-sm-25 me-0"
            ></ArrowLeft>
            <span className="align-middle d-sm-inline-block d-none">Back</span>
          </Button> */}
          <div>
            {/* {file && (
              <Button
                type="button"
                outline
                color="primary"
                className="btn-prev "
                onClick={async () => {
                  setPos({});
                  setCurrentPage(1);
                }}
              >
                <RefreshCcw
                  size={14}
                  className="align-middle me-sm-25 me-0"
                ></RefreshCcw>
                <span className="align-middle d-sm-inline-block d-none">
                  Refresh
                </span>
              </Button>
            )} */}
            {/* {file && (
              <Button
                type="downloadBtn"
                outline
                color="primary"
                className="btn-prev ms-1"
                onClick={() => downloadPDF()}
              >
                <Download
                  size={14}
                  className="align-middle me-sm-25 me-0"
                ></Download>
                <span className="align-middle d-sm-inline-block d-none">
                  Download
                </span>
              </Button>
            )} */}
            {/* <Button
              type="button"
              outline
              color="primary"
              className="btn-prev ms-1"
              onClick={() => handleTestClick()}
            >
              <Send size={14} className="align-middle me-sm-25 me-0"></Send>
              <span className="align-middle d-sm-inline-block d-none">
                Test
              </span>
            </Button> */}
            {/* <Button
              type="button"
              color="primary"
              className="btn-next ms-1"
              onClick={() => sendMessageData()}
            >
              <Send size={14} className="align-middle me-sm-25 me-0"></Send>
              <span className="align-middle d-sm-inline-block d-none">
                Save
              </span>
            </Button> */}
          </div>
        </div>
        <Modal isOpen={isTestOpen} size="sm" className="modal-dialog">
          <ModalHeader toggle={toggleTestModal} className="modal_header ">
            <h5 className="mb-0">Test Message</h5>
          </ModalHeader>
          <ModalBody>
            <Label>Enter Mobile Number</Label>
            <Input
              name="mobile"
              value={testMobile}
              onChange={(e) => {
                setTestMobile(e.target.value);
              }}
              type="number"
            />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between align-items-baseline">
            <div>
              <Button
                color="primary"
                className="mx-25"
                onClick={(e) => {
                  sendTestMessage();
                }}
                disabled={isSubmitting}
              >
                Ok
              </Button>
              <Button
                className="mx-25"
                color="secondary"
                onClick={() => {
                  toggleTestModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* <Modal
          isOpen={sendMessageLoading}
          toggle={() => {}}
          className="modal-dialog-centered bg-transparent background-none transparentModal"
        >
          <div className="bg-transparent">
            {testProgress ? (
              //   <ComponentSpinner />
              <></>
            ) : (
              <Progress value={messageProgress} max={tableData?.length} />
            )}
          </div>
        </Modal> */}
        <Modal
          isOpen={modalSchedule}
          toggle={toggleSchedule}
          className="modal-dialog-centered"
          centered
        >
          <ModalHeader
            toggle={toggleSchedule}
            className="modal_header border-0"
          >
            Schedule Send
          </ModalHeader>
          <ModalBody className="modal_body py-3">
            <div className="position-relative floating-label-content">
              <Input
                type="datetime-local"
                id="schedule-time"
                onChange={inputdata}
                value={formData?.schedule}
                name="schedule"
                min={moment().format("YYYY-MM-DD HH:mm")}
                max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
                className="w-100 bg-white fs-16 floating-textarea manual_input"
              />
              <Label className="bg-white position-absolute floating-label">
                Select Date
              </Label>
              <p className="text-danger">{formError?.schedule}</p>
            </div>
          </ModalBody>
          <ModalFooter className="border-0 d-inline pt-5 modal_footer">
            <div className="text-center">
              <Button
                color="secondary"
                onClick={() => {
                  toggleSchedule();
                }}
                className="mx-25 me-4"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  toggleSchedule();
                }}
                className="mx-25"
                disabled={formError?.schedule ? true : false}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    </Fragment>
  );
};

export default EditPdf;
