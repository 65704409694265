import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Bold,
  Italic,
  // ArrowLeft,
  // ArrowRight,
  // Smile,
  Calendar,
  // Send,  
} from "react-feather";
import {
  Button,
  // Card,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import EmojiPickerWrapper from "../common/EmojiPickerWrapper";
import "../styleModule.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import { convertBase64ToFile, pdfToBase64File } from "../common/Common";
import { Toast } from "react-bootstrap";
// import { notification } from "./common/notification";

const InsertMessage = ({
  tableData,
  fileType,
  stepper,
  csvKeys,
  messageData,
  setMessageData,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  file,
  handleTestClick,
  isTestOpen,
  setIsTestOpen,
  setPos,
  pos,
}) => {
  const inputRef = useRef(null);
  // const navigate = useNavigate();

  const { clientId, id } = useParams();
  const [selection1, setSelection1] = useState([]);
  const [cur, setCur] = useState(0);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [selection, setSelection] = useState();
  const toggleSchedule = () => setModalSchedule(!modalSchedule);
  const expiry_date = useSelector((state) => state.auth.user.expiry_date);
  const company_id = useSelector((state) => state.auth.user.company_id);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  useEffect(() => {
    if (!selection) return; // prevent running on start
    const { start, end } = selection;
    inputRef?.current?.focus();
    inputRef?.current?.setSelectionRange(start, end);
  }, [selection]);

  const addVariableInMessage = (variable) => {
    setMessageData(
      [
        messageData?.slice(0, cur),
        "[[" + variable + "]]",
        messageData?.slice(cur),
      ].join("")
    );

    setCur(cur + `[[${variable}]]`.length);
    setSelection({
      start: cur + `[[${variable}]]`.length,
      end: cur + `[[${variable}]]`.length,
    });
  };

  const onEmojiClick = (e, emojiObject) => {
    setMessageData(
      [messageData.slice(0, cur), e.emoji, messageData.slice(cur)].join("")
    );
    setCur(cur + 2);
    setSelection({
      start: cur + 2,
      end: cur + 2,
    });
  };

  const textFormatting = (type) => {
    if (messageData !== "" && selection1.length > 0) {
      let newMessage = "";
      if (type === "b") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "*",
          messageData.slice(selection1[0], selection1[1]),
          "*",
          messageData.slice(selection1[1]),
        ].join("");
      } else if (type === "i") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "_",
          messageData.slice(selection1[0], selection1[1]),
          "_",
          messageData.slice(selection1[1]),
        ].join("");
      } else if (type === "s") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "~",
          messageData.slice(selection1[0], selection1[1]),
          "~",
          messageData.slice(selection1[1]),
        ].join("");
      }
      setMessageData(newMessage);
      setSelection1([]);
      setCur(newMessage?.length);
    }
  };

  const currentSelection = (e) => {
    setSelection1([e.target.selectionStart, e.target.selectionEnd]);
  };

  const handleChangeForMessage = (e) => {
    e.preventDefault();
    setMessageData(e.target.value);
  };

  const onBlur = (e) => {
    setCur(e.target.selectionStart);
  };

  // const validateScheduleDate = () => {
  //   if (formData.schedule) {
  //     const selectedDate = moment(formData.schedule);
  //     const currentDate = moment();
  //     if (selectedDate.isBefore(currentDate)) {
  //       setFormError({
  //         ...formError,
  //         schedule: "Scheduled time cannot be in the past.",
  //       });
  //       return false;
  //     } else {
  //       setFormError({ ...formError, schedule: "" });
  //       return true;
  //     }
  //   }
  //   return true;
  // };

  // const handleScheduleChange = (e) => {
  //   setFormData({ ...formData, schedule: e.target.value });
  //   validateScheduleDate();
  // };

  // const validateFields = () => {
  //   if ((messageData === "" || messageData.trim() === "") && !file) {
  //     notification({
  //       type: "error",
  //       title: "Empty Field",
  //       message: "Please enter Message or Media",
  //     });
  //     return false;
  //   }

  //   if (formData.delaySecond === "" || isNaN(formData.delaySecond) || formData.delaySecond < 0 || formData.delaySecond > 10) {
  //     notification({
  //       type: "error",
  //       title: "Invalid Delay",
  //       message: "Delay second must be between 0 and 10",
  //     });
  //     return false;
  //   }

  //   return true;
  // };

  // const handleNext = () => {
  //   if (validateFields()) {
  //     if (messageData.trim() === "") {
  //       setMessageData(messageData.trim());
  //     }
  //     stepper.next();
  //   }
  // };
  const [testMobile, setTestMobile] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleTestModal = () => {
    setTestMobile("");
    setIsSubmitting(false);
    setIsTestOpen(!isTestOpen);
  };

  const sendTestMessage = async () => {
    if (!testMobile) {
      //   notification({
      //     type: "error",
      //     title: "Please enter Mobile number",
      //   });
    } else {

      setIsSubmitting(true);
      // setSendMessageLoading(true);
      // setTestProgress(true);

      try {
        let media = [];
      if (file) {
        const pdfBytes = await changeFont(tableData[0]);
        if (fileType === "application/pdf") {
          media = new File([pdfBytes], `${tableData[0].name}.pdf`, {
            type: "application/pdf;charset=utf-8",
          });
        } else {
          const dataURL = await pdfToBase64File(pdfBytes, fileType);
          media = await convertBase64ToFile(dataURL);
        }
      }
      let newMessage = messageData;
      csvKeys.forEach((item) => {
        newMessage = newMessage.replaceAll(
          `[[${item}]]`,
          tableData[0][item].replaceAll(/;/g, ",").trim()
        );
      });
      let request = new FormData();
      // request.append("connectionId", state.connectionId);
      request.append("campaign_id", id);
      request.append("clientId", clientId);
      request.append("company_id", company_id);
      request.append("message", newMessage);
      request.append(
        "delay",
        formData?.delaySecond ? formData?.delaySecond : 0
      );
      // request.append("schedule", null);
      request.append("numbers", testMobile);
      request.append("name", "Test Message");
      // request.append("total_message", 1);
      request.append(
        "file_name",
        `Test${
          fileType === "application/pdf"
            ? ".pdf"
            : fileType === "image/png"
            ? ".png"
            : ".jpeg"
        }`
      );
      // request.append(
      //   "caption",
      //   formData?.captionData ? formData?.captionData : ""
      // );
      request.append("media", media);

      let response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/send-test-message`,
        request
      );

      console.log("RESPOSNSE: ", response);

      setSnackbarMessage(response.data.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
      // if(response.success){

      // } else{
      //   setSnackbarMessage("Error Sending Test.");
      // setSnackbarVariant("danger");
      // setShowSnackbar(true);

      // }

      //   let response = await sendMessage(request);
      setTestMobile("");
      //   if (response?.status === 0) {
      //     toggleTestModal();
      //     MySwal.fire({
      //       title: response?.description,
      //       icon: "warning",
      //       confirmButtonText: "ok",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //         cancelButton: "btn btn-outline-danger ms-1",
      //       },
      //       buttonsStyling: false,
      //     }).then(() => {
      //       if (response?.description === "insufficient balance") {
      //         navigate("/package");
      //       }
      //     });
      //     setSendMessageLoading(false);
      //   } else {
      //     setTestProgress(false);
      //     setSendMessageLoading(false);
      //     let response1 = await sendLastMessage({
      //       length: 0,
      //       connectionId: state.connectionId,
      //       campaign_id: state?.campaign_id,
      //     });
      //     setSendMessageLoading(false);
      //     // notification({
      //     //   type: response1.status == 1 ? "success" : "error",
      //     //   title: response?.description,
      //     // });
      //   }
      setIsSubmitting(false);
      // setSendMessageLoading(false);
      // setTestProgress(false);
      toggleTestModal();
      setTestMobile("");
        
      } catch (error) {
        setSnackbarMessage("Error Sending Test.");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
      setTestMobile("");
      setIsSubmitting(false);
      toggleTestModal();
        
      }
      
    }
  };

  const changeFont = async (data) => {
    const existingPdfBytes = await fetch(URL.createObjectURL(file)).then(
      (res) => res.arrayBuffer()
    );
    const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDocs1.getPages();
    await pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );
      // let font = await fontkit.create(fontBytes);
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);
    }
    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const {
        // width,
        height,
      } = page.getSize();
      // const textWidth = embeddedFonts[index]?.widthOfTextAtSize(
      //   data[key],
      //   pos[key]?.size
      // );
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  return (
    <Fragment>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      <Row>
        <Col lg="6" sm="3" xs="4" className="border_rightcol p-2">
          <Row>
            <Col xl="12" className="mb-25 ">
              <div className="mb-75">
                <Row className="d-flex align-items-center py-5">
                  <Col lg="9" sm="9" xs="8">
                    <div className=" d-flex my-75  text-light justify-content-between align-items-center">
                      <h4
                        className="ps-1 m-0"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Message
                      </h4>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="4">
                    <div className="pe-1 float-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setMessageData("");
                        }}
                        className="mx-25"
                        type="reset"
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="pt-2">
                  <div className="position-relative floating-label-content">
                    <label className="floating-label text-start w-100">
                      Enter Message
                    </label>
                    <textarea
                      id="message"
                      ref={inputRef}
                      className="manual-textarea w-100 bg-white fs-16 floating-textarea"
                      placeholder=" "
                      autoFocus
                      row={5}
                      name="textMessage"
                      onBlur={onBlur}
                      value={messageData}
                      onChange={handleChangeForMessage}
                      onSelect={currentSelection}
                      style={{
                        height: "150px",
                        borderRadius: "5px",
                        "input[type=text]:focus, textarea:focus": {
                          outline: "none !important",
                          border: "1px solid red",
                          boxShadow: "0 0 10px #719ECE",
                        },
                      }}
                    />
                  </div>
                </div>

                <Row className="d-flex align-items-center py-3">
                  <Col lg="8" className="text-start">
                    {csvKeys &&
                      Array.from(csvKeys).map((rows, i) => {
                        return (
                          rows !== "number" && (
                            <Label
                              key={i}
                              onClick={(e) => {
                                e.preventDefault();
                                addVariableInMessage(rows);
                              }}
                              className="btn_media btn btn-sm p-50 m-25 m-0"
                              style={{ fontWeight: "500", fontSize: "10px" }}
                            >
                              [[{rows}]]
                            </Label>
                          )
                        );
                      })}
                  </Col>
                  <Col lg="4">
                    <div className="d-flex justify-content-center align-items-center  justify-content-lg-end justify-content-md-center justify-content-sm-center p-50">
                      <span className="me-2 curser-pointer">
                        <EmojiPickerWrapper onEmojiClick={onEmojiClick} />
                      </span>
                      <span className="curser-pointer">
                        <Bold
                          onClick={() => {
                            textFormatting("b");
                          }}
                          className="cursor-pointer me-2"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </span>
                      <span className="curser-pointer">
                        <Italic
                          onClick={() => {
                            textFormatting("i");
                          }}
                          className="cursor-pointer me-2"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </span>
                      <span className="curser-pointer">
                        <s className="">
                          <h3
                            onClick={() => {
                              textFormatting("s");
                            }}
                            className="cursor-pointer m-0"
                            style={{ fontWeight: "400" }}
                          >
                            S
                          </h3>
                        </s>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div>
            {/* <div>
          <p className="my-50 text-primary fs-6">
            {formData?.schedule &&
              moment(formData?.schedule).format("DD-MM-YYYY hh:mm A")}
          </p>
        </div> */}

            <Row className="d-flex align-items-center justify-content-between flex-column flex-sm-row flex-md-row flex-lg-row">
              {/* <Col lg="4" md="3" sm="4" xs="12" className="text-start">
            <div className="d-inline-block">
              <Button
                className="d-flex align-items-center justify-content-center w-100"
                color="primary"
                onClick={toggleSchedule}
                onChange={setFormData}
              >
                <Calendar className="me-1" />
                Schedule Send
              </Button>
            </div>
          </Col> */}
              {/* <Col lg="4" md="4" sm="4" xs="12">
                <div className="d-flex align-items-center justify-content-end my-1">
                  <Label className="fs-6 mb-0 text-black me-1">
                    Delay Second
                  </Label>
                  <Input
                    type="text"
                    className="w-25 ms-1 fw-500 border-0 delaysec_input"
                    min={0}
                    max={10}
                    maxLength={2}
                    onChange={inputdata}
                    value={formData?.delaySecond}
                    name="delaySecond"
                  />
                </div>
                <p className="text-danger text-end">{formError?.delaySecond}</p>
              </Col> */}
            </Row>
            {/* <div className="py-3 text-start">
          <p className="my-50 text-primary fs-5">
            {formData?.schedule &&
              moment(formData?.schedule).format("DD-MM-YYYY hh:mm A")}
          </p>
        </div> */}
          </div>
        </Col>
        <Col lg="4" md="3" sm="4" xs="12" className="text-start p-3">
          <div className="mb-4">
            <Label className="fs-6 mb-0 text-black">Delay Seconds</Label>
            <Row className="align-items-center">
              <Col lg="6" md="6" sm="6" xs="12">
                <div className="d-flex align-items-center my-1">
                  <Label className="fs-6 mb-0 text-black me-1">From</Label>
                  <Input
                    type="text"
                    className="w-100 fw-500 border-0 delaysec_input"
                    min={0}
                    max={10}
                    maxLength={2}
                    onChange={inputdata}
                    value={formData?.delayFromSecond}
                    name="delayFromSecond"
                  />
                </div>
                <p className="text-danger">{formError?.delayFromSecond}</p>
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <div className="d-flex align-items-center my-1">
                  <Label className="fs-6 mb-0 text-black me-1">To</Label>
                  <Input
                    type="text"
                    className="w-100 fw-500 border-0 delaysec_input"
                    min={0}
                    max={10}
                    maxLength={2}
                    onChange={inputdata}
                    value={formData?.delayToSecond}
                    name="delayToSecond"
                  />
                </div>
                <p className="text-danger">{formError?.delayToSecond}</p>
              </Col>
            </Row>
          </div>

          <div className="mb-4">
            <Button
              className="d-flex align-items-center justify-content-center w-100"
              color="primary"
              onClick={toggleSchedule}
              onChange={setFormData}
            >
              <Calendar className="me-1" />
              Select Schedule Date
            </Button>
          </div>

          <div className="text-start">
          <p className="my-50 text-primary fs-5">
            {formData?.schedule &&
              moment(formData?.schedule).format("DD-MM-YYYY hh:mm A")}
          </p>
        </div>

          <div className="d-flex justify-content-between">
            {/* <Button
              type="button"
              outline
              color="primary"
              className="btn-prev ms-1"
              onClick={() => handleTestClick()}
            >
              <Send size={14} className="align-middle me-sm-25 me-0"></Send>
              <span className="align-middle d-sm-inline-block d-none">
                Test
              </span>
            </Button> */}
            {/* <Button
              type="button"
              color="primary"
              className="btn-next"
              onClick={() => stepper.next()}
            >
              <span className="align-middle d-sm-inline-block d-none">
                Next
              </span>
              <ArrowRight
                size={14}
                className="align-middle ms-sm-25 ms-0"
              ></ArrowRight>
            </Button> */}
          </div>
        </Col>
      </Row>
      <Modal
        isOpen={modalSchedule}
        toggle={toggleSchedule}
        className="modal-dialog-centered"
        centered
      >
        <ModalHeader toggle={toggleSchedule} className="modal_header border-0">
          Schedule Send
        </ModalHeader>
        <ModalBody className="modal_body py-3">
          <div className="position-relative floating-label-content">
            <Input
              type="datetime-local"
              id="schedule-time"
              onChange={inputdata}
              value={formData?.schedule}
              name="schedule"
              min={moment().format("YYYY-MM-DD HH:mm")}
              max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
              className="w-100 bg-white fs-16 floating-textarea manual_input"
            />
            <Label className="bg-white position-absolute floating-label">
              Select Date
            </Label>
            <p className="text-danger">{formError?.schedule}</p>
          </div>
        </ModalBody>
        <ModalFooter className="border-0 d-inline pt-5 modal_footer">
          <div className="text-center">
            <Button
              color="secondary"
              onClick={() => {
                toggleSchedule();
              }}
              className="mx-25 me-4"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                toggleSchedule();
              }}
              className="mx-25"
              disabled={formError?.schedule ? true : false}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Test Modal  */}
      <Modal isOpen={isTestOpen} size="sm" className="modal-dialog">
        <ModalHeader toggle={toggleTestModal} className="modal_header ">
          <h5 className="mb-0">Test Message</h5>
        </ModalHeader>
        <ModalBody>
          <Label>Enter Mobile Number</Label>
          <Input
            name="mobile"
            value={testMobile}
            onChange={(e) => {
              setTestMobile(e.target.value);
            }}
            type="number"
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between align-items-baseline">
          <div className="d-flex gap-2">
            <Button
              color="primary"
              className="mx-25"
              onClick={(e) => {
                sendTestMessage();
              }}
              disabled={isSubmitting}
            >
              {isSubmitting? 'Sending...' : "Send"}
            </Button>
            <Button
              className="mx-25"
              color="secondary"
              onClick={() => {
                toggleTestModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default InsertMessage;
