import { 
  // Document, 
  // Page, 
  pdfjs } from "react-pdf";
import { PDFDocument, 
  // rgb, 
  // PNGStream 
} from "pdf-lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const dateTimeToUCT = (oldDate) => {
  let year = oldDate.getUTCFullYear();
  let month = oldDate.getUTCMonth() + 1;
  let date = oldDate.getUTCDate();
  let hours = oldDate.getUTCHours();
  let minutes = oldDate.getUTCMinutes();

  const newObjForUTCDate =
    year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":00";

  return newObjForUTCDate;
};

export const getBase64 = async (files) => {
  var base64FileData = new Promise((resolve) => {
    let baseURL = "";
    const reader = new FileReader();
    if (reader) {
      reader?.readAsDataURL(files);
      reader.onload = () => {
        baseURL = reader?.result?.split(",")[1];
        resolve(baseURL);
      };
    } else {
      resolve("");
    }
  });
  return base64FileData;
};

export const convertBase64ToFile = async (base64) => {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: "image/jpeg",
  });
  return newBlob;
};

export const pdfToBase64File = async (pdfBytes, fileType) => {
  const loadingTask = pdfjs.getDocument(pdfBytes);
  // console.log("loadingTask", loadingTask);
  const pdf = await loadingTask.promise;
  // const pages = pdf.numPages;
  const page = await pdf.getPage(1);
  // console.log("?", await pdf.getPage(1));

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const viewport = page.getViewport({ scale: 1 });
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
  console.log(
    fileType,
    "sdfgjdsfgvjdf",
    fileType === "image/png" ? "image/png" : "image/jpeg"
  );
  const dataURL = canvas.toDataURL(
    fileType === "image/png" ? "image/png" : "image/jpeg"
  );
  return dataURL;
};

export const pdfCompress = async (pdfBytes) => {
  // console.log("pdfBytes", pdfBytes);
  //   const pdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const compressedPdfBytes = await pdfDoc.save({
    compress: true, // set the PDF compression option to true
  });

  // const compressedPdfFile = new File([compressedPdfBytes], file.name, {
  //   type: file.type, // Set the file type to the original PDF File type
  // });
  return compressedPdfBytes;
};
