import React, { useState } from "react";
import CommonLayout from "../../layouts/master/index";
// import { Button } from "react-bootstrap";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import { useSelector } from "react-redux";

export default function Customers() {
  const id = useSelector((state) => state.auth.user.id);
  console.log("ID : ", id);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentResellerId, setCurrentResellerId] = useState(null);
  const [
    resellers,
    // setResellers
  ] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [
    limit,
    // setLimit
  ] = useState(10);
  const [
    searchTerm,
    // setSearchTerm
  ] = useState("");

  const fetchResellers = async () => {};

  const handleAddReseller = (reseller) => {
    setShowAddModal(false);
  };

  // const handleEditReseller = (reseller) => {
  //     setShowEditModal(false);
  // };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleDeleteReseller = (id) => {};
  return (
    <>
      <CommonLayout>
        {/* <div style={{ marginLeft: "1400px", marginTop: "20px" }}>
          <Button
            variant="primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Reseller
          </Button>
        </div> */}
        <div>
          <List
            resellers={resellers}
            editHandler={(id) => {
              setCurrentResellerId(id);
              setShowEditModal(true);
            }}
            deleteHandler={handleDeleteReseller}
            handleClose={() => setShowAddModal(false)}
            handleOpenModal={handleOpenModal}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            searchTerm={searchTerm}
            id={id}
          />
          <Add
            show={showAddModal}
            handleClose={handleCloseModal}
            saveReseller={handleAddReseller}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            searchTerm={searchTerm}
            id={id}
          />
          {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              resellerId={currentResellerId}
              updateResellersList={fetchResellers}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              limit={limit}
              searchTerm={searchTerm}
              id={id}
            />
          )}
        </div>
      </CommonLayout>
    </>
  );
}
