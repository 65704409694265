import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { Image, Dropdown, ListGroup } from "react-bootstrap";

import "simplebar/dist/simplebar.min.css";
import { useNavigate } from "react-router-dom";
import avatar from "../../../../styles/images/avatar/avatar-1.jpg";
import useMounted from "../../../../hooks/useMounted";
import { userLogout } from "../../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";

const NavbarTopMenu = () => {
  const hasMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // console.log("User Details : ", user);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const handleLogOut = async () => {
    try {
      await dispatch(userLogout()).then(() => {
        // console.log("hello");
        if (hasMounted) {
          navigate("/", { replace: true });
        }
        // console.log("Logout function called");
      });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleWalletClick = () => {
    navigate("/wallet");
  };

  const getUserInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "U";
  };

  const NavbarTopMenuDesktop = () => {
    return (
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap"
      >
        {/* <Dropdown as="li" className="stopevent">
                    <Dropdown.Toggle as="a"
                        bsPrefix=' '
                        id="dropdownNotification"
                        className="btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted">
                        <i className="fe fe-bell"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end py-0"
                        aria-labelledby="dropdownNotification"
                        align="end"
                        show
                        >
                        <Dropdown.Item className="mt-3" bsPrefix=' ' as="div">
                            <div className="border-bottom px-3 pt-0 pb-3 d-flex justify-content-between align-items-end">
                                <span className="h4 mb-0">Notifications</span>
                                <Link to="/" className="text-muted">
                                    <span className="align-middle">
                                        <i className="fe fe-settings me-1"></i>
                                    </span>
                                </Link>
                            </div>
                            <div className="border-top px-3 pt-3 pb-3">
                                <Link to="/dashboard/notification-history" className="text-link fw-semi-bold">
                                    See all Notifications
                                </Link>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
        <span className="me-2 fw-bold">{user.name}</span>
        <Dropdown as="li" className="ms-2">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              {/* <Image alt="avatar" src={avatar} className="rounded-circle" /> */}
              <div
                className="rounded-circle bg-primary d-flex justify-content-center align-items-center"
                style={{
                  width: 40,
                  height: 40,
                  color: "white",
                  fontSize: "1.25rem",
                }}
              >
                {getUserInitial(user.name)}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
            show
          >
            <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
              <div className="lh-1">
                <h5 className="mb-1">{user.name}</h5>
                {/* <Link to="#" className="text-inherit fs-6">
                  View my profile
                </Link> */}
              </div>
              <div className="dropdown-divider mt-3 mb-2"></div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/profile")}>
              <i className="fe fe-user me-2"></i> My Profile
            </Dropdown.Item>
            {/* <Dropdown.Item>
                            <i className="fe fe-activity me-2"></i> Activity Log
                        </Dropdown.Item>
                        <Dropdown.Item className="text-primary">
                            <i className="fe fe-star me-2"></i> Go Pro
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <i className="fe fe-settings me-2"></i> Account Settings
                        </Dropdown.Item> */}
            <Dropdown.Item onClick={handleWalletClick}>
              <i className="fe fe-user me-2"></i> Wallet
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogOut}>
              <i className="fe fe-power me-2"></i>Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    );
  };

  const NavbarTopMenuMobile = () => {
    return (
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex nav-top-wrap"
      >
        {/* <Dropdown as="li" className="stopevent">
                    <Dropdown.Toggle as="a"
                        bsPrefix=' '
                        id="dropdownNotification"
                        className="btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted">
                        <i className="fe fe-bell"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end py-0"
                        aria-labelledby="dropdownNotification"
                        align="end"
                        >
                        <Dropdown.Item className="mt-3" bsPrefix=' ' as="div">
                            <div className="border-bottom px-3 pt-0 pb-3 d-flex justify-content-between align-items-end">
                                <span className="h4 mb-0">Notifications</span>
                                <Link to="/" className="text-muted">
                                    <span className="align-middle">
                                        <i className="fe fe-settings me-1"></i>
                                    </span>
                                </Link>
                            </div>
                            <div className="border-top px-3 pt-3 pb-3">
                                <Link to="/dashboard/notification-history" className="text-link fw-semi-bold">
                                    See all Notifications
                                </Link>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
        <Dropdown as="li" className="ms-2">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image alt="avatar" src={avatar} className="rounded-circle" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
          >
            <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
              <div className="lh-1">
                <h5 className="mb-1">{user.name}</h5>
                {/* <Link to="#" className="text-inherit fs-6">
                  View my profile
                </Link> */}
              </div>
              <div className="dropdown-divider mt-3 mb-2"></div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/profile")}>
              <i className="fe fe-user me-2"></i> My Profile
            </Dropdown.Item>
            {/* <Dropdown.Item>
                            <i className="fe fe-activity me-2"></i> Activity Log
                        </Dropdown.Item>
                        <Dropdown.Item className="text-primary">
                            <i className="fe fe-star me-2"></i> Go Pro
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <i className="fe fe-settings me-2"></i> Account Settings
                        </Dropdown.Item> */}
            <Dropdown.Item onClick={handleWalletClick}>
              <i className="fe fe-power me-2"></i>Wallet
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogOut}>
              <i className="fe fe-power me-2"></i>Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    );
  };

  return (
    <Fragment>
      {hasMounted && isDesktop ? (
        <NavbarTopMenuDesktop />
      ) : (
        <NavbarTopMenuMobile />
      )}
    </Fragment>
  );
};

export default NavbarTopMenu;
