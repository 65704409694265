import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  // FormCheck as Switch,
  Badge,
  Dropdown,
  Modal,
} from "react-bootstrap";
import {
  deleteCustomer,
  fetchEvents,
  // deleteReseller,
  // updateReseller,
} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
// import { MoreVertical } from "react-feather";
import "./styleModule.css";
import { Link } from "react-router-dom";
import SortImage from "../../../sort.svg";
import PaginationComponent from "../pagination/pagination";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import { MoreVertical } from "react-feather";
import Edit from "./Edit";

export default function List({
  editHandler,
  handleOpenModal,
  currentPage,
  setCurrentPage,
  searchTerm,
  limit,
  id,
}) {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [
    snackbarMessage,
    setSnackbarMessage
  ] = useState("");
  const [
    snackbarVariant,
    setSnackbarVariant
  ] = useState("success");
  const events = useSelector((state) => state.events.events);
  // const countries = useSelector((state) => state.events.countries);
  const totalPages = useSelector((state) => state.events.totalPages);
  const total = useSelector((state) => state.events.total);
  // const id = useSelector((state) => state.auth.user.id)
  // console.log('ID : ', id);
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getEventsList = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchEvents({ offset: currentPage, limit, search: searchTerm, id: id })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, currentPage, limit, searchTerm, id, setLoading]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const navigateToDetails = (id) => {
    navigate(`/customers/${id}`);
  };

  const handleEditClick = (customer) => {
    setCurrentCustomer(customer); // Set the current customer data
    setShowEditModal(true); // Show the Edit modal
  };

  const handleDeleteCustomerClick = (customer) => {
    setCurrentCustomer(customer); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCustomer({ id: currentCustomer.id }));
      setSnackbarMessage("Campaign deleted successfully");
      setSnackbarVariant("success");
      setShowSnackbar(true);
      getEventsList(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  const ActionMenu = ({ customer }) => {
    return (
      <Dropdown drop="start" className="position-static">
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item eventKey="1" onClick={() => handleEditClick(customer)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              navigateToDetails(customer?.id);
            }}
          >
            View
          </Dropdown.Item>
          {customer?.campaigns_count === 0 && (
          <Dropdown.Item
            eventKey="3"
            onClick={() => handleDeleteCustomerClick(customer)}
          >
            Delete
          </Dropdown.Item>
        )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          {snackbarMessage}
        </Toast.Body>
      </Toast>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            {/* Page header */}
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0  text-white">Customers List</h3>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="tablecontainer position-relative overflow-hidden">
              <div className="input_btn">
                <div className="float-end">
                  <Button
                    variant="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Add Customer
                  </Button>
                </div>
              </div>
              <div className="position-relative overflow-auto">
                <Table className="text-nowrap data_list text-start ">
                  <thead className="table-light">
                    <tr>
                      {/* <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Sr.</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th> */}
                      <th scope="col">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="">Name</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="">Contact Number</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Campaigns</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Created At</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>WhatsApp Status</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Action</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.length === 0 ? (
                      <tr>
                        <td
                          className="mt-5 fw-bold fs-5 text-center"
                          colSpan="7"
                        >
                          No data available
                        </td>
                      </tr>
                    ) : (
                      events.map((event, index) => (
                        <tr
                          key={event?.id}
                          // onClick={() => navigateToDetails(event?.id)}
                          className="data_row"
                        >
                          {/* <td>{(index + 1) + currentPage * 10}</td> */}
                          <td className="text-capitalized">{event?.name}</td>
                          <td>{event?.contact_number}</td>
                          <td>{event?.campaigns_count}</td>
                          <td>
                            {moment(event?.created_at).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {event?.whatsapp_client?.is_active ? (
                              <Badge pill bg="success">
                                Connected
                              </Badge>
                            ) : (
                              <Badge pill bg="danger">
                                Not Connected
                              </Badge>
                            )}
                          </td>
                          <td>
                            <ActionMenu
                            // campaing={campaing}
                            // showSend={campaing?.message ? false : true}
                            customer={event}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 ? (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Customer?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>

      {showEditModal && (
        <Edit
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          limit={limit}
          id={id}
          currentCustomer={currentCustomer} 
          setShowSnackbar={setShowSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarVariant={setSnackbarVariant}
        />
      )}
    </>
  );
}
