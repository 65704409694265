import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  // Toast,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormLabel,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { fetchEvents, updateCustomer } from "../../../store/eventsSlice";
import * as Yup from "yup";

export default function Edit({
  show,
  handleClose,
  currentPage,
  setCurrentPage,
  searchTerm,
  limit,
  id,
  currentCustomer, // Receive the current customer data
  setShowSnackbar,
  setSnackbarMessage,
  setSnackbarVariant,

}) {
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const initialEventState = {
    name: currentCustomer?.name || "",
    contact_number: currentCustomer?.contact_number || "",
    email: currentCustomer?.email || "",
    address: currentCustomer?.address || "",
  };

  const eventValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    email: Yup.string().email("Invalid email address").nullable(),
    address: Yup.string().nullable(),
  });

  const handleModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    setIsSubmitting(true)
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("contact_number", values.contact_number);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("_method", "PUT");

    dispatch(updateCustomer({ id: currentCustomer?.id, data: formData }))
      .then((res) => {
        if (res.payload.success) {
          dispatch(fetchEvents({
            offset: currentPage,
            limit,
            search: searchTerm,
            id: id                    
          }));
          console.log("REsponse: ", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);

          // console.log("hahahahahahahah : ", showSnackbar);
          
        } else {
          setSnackbarMessage(res.payload.message);
          setErrors(res.payload.data);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update customer: " + error.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setShowSnackbar(true)
        resetForm();
          handleModalClose();
        setSubmitting(false);
      });
  };

  // useEffect(() => {
  //   if (showSnackbar) {
  //     console.log("Snackbar is now shown");
  //   }
  // }, [showSnackbar]);

  return (
    <>
    {/* {console.log("Toast : ", showSnackbar)} */}
      {/* <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast> */}

      <Modal show={show} size="md" className="" centered>
        <ModalHeader className=" ">
          <h3 className="text-primary mb-0">Edit Customer</h3>
        </ModalHeader>
        <Formik
          initialValues={initialEventState}
          validationSchema={eventValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange }) => (
            <Form encType="multipart/form-data">
              <ModalBody>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                  placeholder="Enter name"
                  onChange={handleChange}
                />
                {errors.name && touched.name ? (
                  <div className="invalid-feedback">{errors.name}</div>
                ) : null}
                <br />

                <FormLabel htmlFor="contact_number">Contact Number</FormLabel>
                <Field
                  id="contact_number"
                  name="contact_number"
                  type="text"
                  className={`form-control ${
                    errors.contact_number && touched.contact_number
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter contact number"
                  onChange={handleChange}
                />
                {errors.contact_number && touched.contact_number ? (
                  <div className="invalid-feedback">{errors.contact_number}</div>
                ) : null}
                <br />

                <FormLabel htmlFor="email">Email</FormLabel>
                <Field
                  name="email"
                  type="text"
                  className={`form-control ${
                    errors.email && touched.email
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Email"
                  onChange={handleChange}
                />
                {errors.email && touched.email ? (
                  <div className="invalid-feedback">
                    {errors.email}
                  </div>
                ) : null}
                <br />

                <FormLabel htmlFor="address">Address</FormLabel>
                <Field
                  name="address"
                  type="text"
                  className={`form-control ${
                    errors.address && touched.address
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Address"
                  onChange={handleChange}
                />
              </ModalBody>
              <ModalFooter className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting? "Updating...": "Update"}
                </Button>
                <Button
                  className="mx-25"
                  color="danger"
                  variant="secondary"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
