// import Aakar from "./aakar.ttf";
import aakar from "./aakar.ttf"
import shruti from "./shruti.ttf";
// import Rasa from "./Rasa.ttf"
// import Bhuj from "./bhuj.ttf";
// import EkatraBold from "./Ekatra-B.ttf";
// // import Gopika from "./Gopika.ttf";
// import HindVadodaraBold from "./HindVadodara-Bold.ttf";
// import KumarOneRegular from "./HindVadodara-Bold.ttf";
// import LohitGujarati from "./Lohit_Gujarati.ttf";
// import Mogra from "./Mogra.ttf";
// import MuktaVaaniBold from "./MuktaVaani-Bold.ttf";
// import MuktaVaaniExtraBold from "./MuktaVaani-ExtraBold.ttf";
// import Padmaa from "./Padmaa.ttf";
// import PadmaaBold from "./Padmaa_Bold.ttf";
// import Rekha from "./Rekha.ttf";
// import Rajkot from "./RAJKOT-UNICODE.ttf";
// import Helvetica from "./Helvetica.ttf";
// import HelveticaBold from "./Helvetica-Bold.ttf";
// import HelveticaBoldOblique from "./Helvetica-BoldOblique.ttf";
import TimesNewRoman from "./timesNewRoman.ttf";
// import TimesNewRomanBold from "./timesNewRomanBold.ttf";
// import TimesNewRomanBoldItalic from "./timesNewRomanBoldItalic.ttf";
// import TimesNewRomanItalic from "./timesNewRomanItalic.ttf";
// import CourierNew from "./CourierNew.ttf";
// import CourierBold from "./CourierBold.ttf";
// import HelveticaBoldOblique from "./Helvetica-BoldOblique.ttf";

// export const fontStyleOptions = [
//   {
//     value: "Aakar",
//     label: "આકાર",
//     file: Aakar,
//     style: { fontFamily: "Aakar" },
//   },

//   {
//     value: "Bhuj",
//     label: "ભુજ",
//     file: Bhuj,
//     style: { fontFamily: "Bhuj" },
//   },
//   {
//     value: "EkatraBold",
//     label: "એકત્રા બોલ્ડ",
//     file: EkatraBold,
//     style: { fontFamily: "EkatraBold" },
//   },
//   {
//     value: "HindVadodaraBold",
//     label: "હિંદ વડોદરા બોલ્ડ",
//     file: HindVadodaraBold,
//     style: { fontFamily: "HindVadodaraBold" },
//   },
//   {
//     value: "KumarOneRegular",
//     label: "કુમાર વન રેગ્યુલર",
//     file: KumarOneRegular,
//     style: { fontFamily: "KumarOneRegular" },
//   },
//   {
//     value: "LohitGujarati",
//     label: "લોહિત ગુજરાતી",
//     file: LohitGujarati,
//     style: { fontFamily: "LohitGujarati" },
//   },
//   {
//     value: "Mogra",
//     label: "મોગરા",
//     file: Mogra,
//     style: { fontFamily: "Mogra" },
//   },
//   {
//     value: "MuktaVaaniBold",
//     label: "મુક્તા વાણી બોલ્ડ",
//     file: MuktaVaaniBold,
//     style: { fontFamily: "MuktaVaaniBold" },
//   },
//   {
//     value: "MuktaVaaniExtraBold",
//     label: "મુક્તા વાણી એક્સ્ટ્રા બોલ્ડ",
//     file: MuktaVaaniExtraBold,
//     style: { fontFamily: "MuktaVaaniExtraBold" },
//   },
//   {
//     value: "Padmaa",
//     label: "પદ્મા",
//     file: Padmaa,
//     style: { fontFamily: "Padmaa" },
//   },
//   {
//     value: "PadmaaBold",
//     label: "પદ્મા બોલ્ડ",
//     file: PadmaaBold,
//     style: { fontFamily: "PadmaaBold" },
//   },
//   {
//     value: "Rajkot",
//     label: "રાજકોટ",
//     file: Rajkot,
//     style: { fontFamily: "Rajkot" },
//   },
//   {
//     value: "Rekha",
//     label: "રેખા",
//     file: Rekha,
//     style: { fontFamily: "Rekha" },
//   },
//   {
//     value: "Shruti",
//     label: "શ્રુતિ",
//     file: Shruti,
//     style: { fontFamily: "Shruti" },
//   },
//   {
//     value: "CourierNew",
//     label: "Courier",
//     file: CourierNew,
//     style: { fontFamily: "CourierNew" },
//   },
//   // {
//   //   value: "CourierBold",
//   //   label: "CourierBold",
//   //   file: CourierBold,
//   //   style: { fontFamily: "CourierBold" },
//   // },

//   // {
//   //   value: "Courier",
//   //   label: "Courier",
//   //   style: { fontFamily: "courier, courier new" },
//   // },
//   // {
//   //   value: "CourierBold",
//   //   label: "CourierBold",
//   //   style: { fontFamily: "Courier, sans-serif", fontWeight: "700" },
//   // },
//   // {
//   //   value: "CourierBoldOblique",
//   //   label: "CourierBoldOblique",
//   //   style: {
//   //     fontFamily: "Courier, sans-serif",
//   //     fontWeight: "700",
//   //     fontStyle: "italic",
//   //   },
//   // },
//   // {
//   //   value: "CourierOblique",
//   //   label: "CourierOblique",
//   //   style: { fontFamily: "Courier, sans-serif", fontStyle: "italic" },
//   // },
//   {
//     value: "Helvetica",
//     label: "Helvetica",
//     file: Helvetica,
//     style: { fontFamily: "Helvetica" },
//   },
//   {
//     value: "HelveticaBold",
//     label: "Helvetica Bold",
//     file: HelveticaBold,
//     style: { fontFamily: "HelveticaBold" },
//   },
//   {
//     value: "HelveticaBoldOblique",
//     label: "Helvetica Bold Oblique",
//     file: HelveticaBoldOblique,
//     style: { fontFamily: "HelveticaBoldOblique" },
//   },
//   // {
//   //   value: "Helvetica",
//   //   label: "Helvetica",
//   //   style: { fontFamily: "Helvetica, sans-serif" },
//   // },
//   // {
//   //   value: "HelveticaBold",
//   //   label: "HelveticaBold",
//   //   style: { fontFamily: "Helvetica, sans-serif", fontWeight: "700" },
//   // },
//   // {
//   //   value: "HelveticaBoldOblique",
//   //   label: "HelveticaBoldOblique",
//   //   style: {
//   //     fontFamily: "Helvetica, sans-serif",
//   //     fontWeight: "700",
//   //     fontStyle: "italic",
//   //   },
//   // },
//   // {
//   //   value: "HelveticaOblique",
//   //   label: "HelveticaOblique",
//   //   style: { fontFamily: "Helvetica, sans-serif", fontStyle: "italic" },
//   // },
//   {
//     value: "TimesNewRoman",
//     label: "Times New Roman",
//     file: TimesNewRoman,
//     style: { fontFamily: "TimesNewRoman" },
//   },
//   {
//     value: "TimesNewRomanBold",
//     label: "Times New Roman Bold",
//     file: TimesNewRomanBold,
//     style: { fontFamily: "TimesNewRomanBold" },
//   },
//   {
//     value: "TimesNewRomanBoldItalic",
//     label: "Times New Roman Bold Italic",
//     file: TimesNewRomanBoldItalic,
//     style: { fontFamily: "TimesNewRomanBoldItalic" },
//   },
//   {
//     value: "TimesNewRomanItalic",
//     label: "Times New Roman Italic",
//     file: TimesNewRomanItalic,
//     style: { fontFamily: "TimesNewRomanItalic" },
//   },
//   // {
//   //   value: "TimesRoman",
//   //   label: "TimesRoman",
//   //   style: { fontFamily: "Times, sans-serif" },
//   // },
//   // {
//   //   value: "TimesRomanBold",
//   //   label: "TimesRomanBold",
//   //   style: { fontFamily: "Times, sans-serif", fontWeight: "700" },
//   // },
//   // {
//   //   value: "TimesRomanBoldItalic",
//   //   label: "TimesRomanBoldItalic",
//   //   style: {
//   //     fontFamily: "Times, sans-serif",
//   //     fontWeight: "700",
//   //     fontStyle: "italic",
//   //   },
//   // },
//   // {
//   //   value: "TimesRomanItalic",
//   //   label: "TimesRomanItalic",
//   //   style: { fontFamily: "Times, sans-serif", fontStyle: "italic" },
//   // },
//   // {
//   //   value: "ZapfDingbats",
//   //   label: "ZapfDingbats",
//   //   style: { fontFamily: "Quattrocento Sans" },
//   // },
// ];
export const fontStyleOptions = [
    {
        value: "Aakar",
        label: "આકાર",
        file: aakar,
        style: { fontFamily: "aakar" },
      },
      {
        value: "Shruti",
        label: "શ્રુતિ",
        file: shruti,
        style: { fontFamily: "shruti" },
      },
        {
    value: "TimesNewRoman",
    label: "Times New Roman",
    file: TimesNewRoman,
    style: { fontFamily: "TimesNewRoman" },
  },
  // {
  //   value: "Rasa",
  //   label: "Rasa",
  //   file: Rasa,
  //   style: { fontFamily: "Rasa" },
  // },
  ];