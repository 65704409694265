import { Row, Col, Card, Button, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../store/authSlice';
import { loginSchema } from '../../../schema/validationSchemas';
import { useNavigate } from 'react-router-dom';

const LogIn = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(userLogin(values)).unwrap();
      if (result && result.success) {
        // console.log('Result : ', result); 
        setToastMessage(result.message || 'Login successful!');
        setToastVariant('success');
        navigate('/dashboard');
      } else {
        setToastMessage(result.message || 'Failed to login!');
        setToastVariant('danger');
        navigate('/');
      }
    } catch (err) {
      const errorMessage = err?.message || 'Failed to login!';
      setToastMessage(errorMessage);
      setToastVariant('danger');
      navigate('/'); 
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };
  
  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant('danger');
      setShowToast(true);
    }

    if (status === 'loading') {
      setToastMessage('Logging in...');
      setToastVariant('warning');
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <Row className="align-items-center justify-content-center g-0 min-vh-100">
      <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
        <Card className="smooth-shadow-md">
          <Card.Body className="p-6">
            <div className="mb-4">
              <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
              <p className="d-flex mb-6 ml-2">Please enter your user information.</p>
            </div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="d-flex form-label">Email or Username</label>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter your email or username here"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="d-flex form-label">Password</label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      placeholder="**************"
                      className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.password && errors.password ? (
                      <div className="invalid-feedback">{errors.password}</div>
                    ) : null}
                  </div>

                  <div className="d-grid w-28 d-flex justify-content-center mt-5">
                    <Button variant="primary" type="submit" disabled={isSubmitting}>Log In</Button>
                  </div>
                  <div className="d-grid w-28 d-flex justify-content-center mt-3">
                    <Link to="/forgot-password" className='text-decoration-underline'>Forgot Password</Link>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default LogIn;