import React from 'react';
import './ProgressBar.css'; // Create a separate CSS file for styling

const ProgressBar = ({ steps, currentStep }) => {
    const percentage = (currentStep / (steps.length - 1)) * 100;

    return (
        <div className="progress-container">
            <div className='progress bglight_bar'>
                <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
            </div>
            <div className="steps">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className={`step ${index === currentStep ? 'active' : ''} ${index < currentStep ? 'completed' : ''}`}
                    >
                        <div className="circle">
                        {index < currentStep && <span className="tick">✔</span>}
                        </div>
                        <div className="label">{step.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;
