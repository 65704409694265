import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchWalletDetails = createAsyncThunk(
  "customer/fetchWalletDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/customer/credits/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, status },
      });
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchMyCredit = createAsyncThunk(
  "campaign/fetchMyCredit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/customer/credit-details/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const addCreditRequest = createAsyncThunk(
  'customer/addCreditRequest',
  async (creditData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/customer/credit-request', creditData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    walletDetails: [],
    myCredit: {},
    total: 0,
    page: 1,
    limit: 10,
    status: 'idle',
    error: null,
    totalPages: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletDetails.fulfilled, (state, action) => {
        state.walletDetails = action.payload.data;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchMyCredit.fulfilled, (state, action) => {
        state.myCredit = action.payload;
      })
  },
});

export default walletSlice.reducer;
