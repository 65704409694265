import React, { useEffect, useState, useCallback } from "react";
import "./walletModule.css";
import {
  Col,
  Container,
  Row,
  ProgressBar,
  Table,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletDetails, fetchMyCredit } from "../../../store/walletSlice";
import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
import { ArrowUp, ArrowDown } from "react-feather";
import CommonLayout from "../../layouts/master/index";
import { useAuth } from "../../../context/AuthContext";
import { useLoader } from "../../../context/LoaderContext";

export default function Wallet() {
  // const [modallist, setModalList] = useState(false);

  // const handleCloseModal = () => {
  //     setModalList(false);
  //     // setCurrentReseller(null);
  // };
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  // const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [activeTab, setActiveTab] = useState(null);

  const { user } = useAuth();
  const walletCreditDetail = useSelector((state) => state.wallet.myCredit);

  const selectWalletState = (state) => state.wallet.walletDetails;

  const selectWalletData = createSelector(
    [selectWalletState],
    (walletDetails) => {
      return {
        total: walletDetails.total,
        credits: walletDetails.data,
      };
    }
  );

  // console.log("Wallet : ", selectWalletData);

  const { total, credits } = useSelector(selectWalletData);

  // const firstRender = useRef(true);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchMyCredit(user.id));
  //     dispatch(
  //       fetchWalletDetails({
  //         id: user.id,
  //         offset: currentPage,
  //         limit,
  //         // search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder,
  //       })
  //     );
  //   }
  // }, [dispatch, user.id, currentPage, limit, sortKey, sortOrder]);

  const getCampaignDetail = useCallback(() => {
    setLoading(true);
    dispatch(fetchMyCredit(user.id));
    dispatch(
      fetchWalletDetails({
        id: user.id,
        offset: currentPage,
        limit,
        // search: searchTerm,
        sort_by_key: sortKey,
        sort_by_value: sortOrder,
        status: activeTab,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    user.id,
    sortKey,
    sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getCampaignDetail();
  }, [getCampaignDetail]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        />
      </>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const usedCredits = (available_credits, total_credits, reserve_credits) => {
    if (total_credits === 0) {
      return 0;
    }
    return Math.round(
      ((total_credits - available_credits - reserve_credits) / total_credits) *
        100
    );
  };

  const handleTabSelect = (key) => {
    if (key === "") {
      setActiveTab(null);
    } else {
      setActiveTab(key);
    }

    setCurrentPage(0);
  };

  return (
    <>
      <CommonLayout>
        <div className="bg-primary pt-10 pb-21"></div>
        <Container fluid className="mt-n22 px-6">
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div>
                <div className="d-flex justify-content-between align-items-center top_title">
                  <div className="mb-2 mb-lg-0">
                    <h3 className="mb-0 subhead_title text-white">Wallet</h3>
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
              <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
                <div className="row justify-content-between">
                  <div className="col-8 p-4">
                    <div className="position-relative d-flex justify-content-between input_head cerdit_list">
                      <h5 className="float-start m-0 fw-500 credit_list">
                        Credit History
                      </h5>
                      {/* <input
                        type="text"
                        placeholder="Search"
                        className="searchbar float-end"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setCurrentPage(0);
                        }}
                      /> */}
                    </div>
                    <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-2">
                      <Tabs
                        defaultActiveKey=""
                        className="pb-0 table-tabs-items"
                        id="uncontrolled-tab-example"
                        onSelect={handleTabSelect}
                      >
                        <Tab
                          eventKey=""
                          title={<p className="mb-0">All</p>}
                        ></Tab>
                        <Tab
                          eventKey="placed"
                          title={<p className="mb-0">Requested</p>}
                        ></Tab>
                        <Tab
                          eventKey="completed"
                          title={<p className="mb-0">Completed</p>}
                        ></Tab>
                        <Tab
                          eventKey="rejected"
                          title={<p className="mb-0">Rejected</p>}
                        ></Tab>
                      </Tabs>
                    </div>
                    <div className="position-relative overflow-auto">
                      <Table className="text-nowrap text-start data_list">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  Purchase Date {renderSortIcons("created_at")}
                                </span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Description</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Credit Rate</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Credits</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Total Price</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Status</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="credit_data">
                          {credits?.length > 0 ? (
                            credits.map((credit, i) => (
                              <tr key={i} className="data_row">
                                <td>{formatDate(credit.created_at)}</td>
                                <td>{credit.description || "-"}</td>
                                <td>₹ {credit.credit_rate}</td>
                                <td>{credit.credits}</td>
                                <td>₹ {credit.price}</td>
                                <td>
                                  {credit.status === "completed" ? (
                                    <Badge pill bg="success">
                                      Completed
                                    </Badge>
                                  ) : credit.status === "rejected" ? (
                                    <Badge pill bg="danger">
                                      Rejected
                                    </Badge>
                                  ) : (
                                    <Badge pill bg="primary">
                                      Placed
                                    </Badge>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                className="mt-5 fw-bold fs-5 text-center"
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {total > limit ? (
                      <PaginationComponent
                        totalItems={total}
                        itemsPerPage={limit}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    ) : null}
                  </div>
                  <div className="col-4">
                    <div className="position-relative border-radius-8 p-4 bg_lightgray">
                      <div className="position-relative d-flex align-items-center justify-content-between input_head cerdit_list">
                        <h5 className="float-start m-0 fw-500 credit_list">
                          My Credit
                        </h5>
                        <div className="float-end d-flex align-items-center light_purple">
                          <img
                            src="/images/wallet-creditcoins/01.svg"
                            alt=""
                            className="me-2"
                          />
                          <span className="fw-500 credit_points">
                            {walletCreditDetail.available_credits}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="d-flex align-items-center justify-content-between pb-2 credit_used">
                          <span className="fw-500 fs-16">Credit Reserved</span>
                          <span className="fw-500 fs-16">
                            {walletCreditDetail.reserve_credits}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pb-2 credit_used">
                          <span className="fw-500 fs-16">Credit Used</span>
                          <span className="fw-500 fs-16">
                            {walletCreditDetail.total_credits -
                              walletCreditDetail.available_credits -
                              walletCreditDetail.reserve_credits}
                          </span>
                        </div>
                        <ProgressBar
                          now={usedCredits(
                            walletCreditDetail.available_credits,
                            walletCreditDetail.total_credits,
                            walletCreditDetail.reserve_credits
                          )}
                          label={`${usedCredits(
                            walletCreditDetail.available_credits,
                            walletCreditDetail.total_credits,
                            walletCreditDetail.reserve_credits
                          )}%`}
                          className="credit_progressbar"
                        />{" "}
                      </div>
                      <div className="text-center fs-16 pt-5 request_credit">
                        {/* <Button className='border-radius-8' onClick={() => { setModalList(true) }}>Request For Credit</Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </CommonLayout>

      {/* <RequestModal show={modallist} handleClose={handleCloseModal} /> */}
    </>
  );
}
