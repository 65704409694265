import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tabs,
  Tab,
  Form,
  FormCheck,
  Toast,
} from "react-bootstrap";
import {
  fetchAllCampaigns,
  fetchEvents,
  addCampaign,
  deleteCampaign,
} from "../../../store/eventsSlice";
import { Container, Col, Row, Dropdown } from "react-bootstrap";
import { MoreVertical } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
// import SortImage from "../../../sort.svg";
import PaginationComponent from "../pagination/pagination";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import "./styleModule.css";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import Select from "react-select";

export default function List() {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const id = useSelector((state) => state.auth.user.id);
  const campaings = useSelector((state) => state.events?.allCampaings?.data);
  const customers = useSelector((state) => state.events?.events); // Fetch customers
  const totalPages = useSelector(
    (state) => state.events?.allCampaings?.total_pages
  );
  const total = useSelector((state) => state.events?.allCampaings?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit] = useState(10);
  const [searchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer
  const [isWhatsappConnected, setIsWhatsappConnected] = useState(false); // State to check WhatsApp connection
  const [showWhatsappModal, setShowWhatsappModal] = useState(false); // Modal for WhatsApp connection
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const navigate = useNavigate();

  const getEventsDetailsData = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchAllCampaigns({
        offset: currentPage,
        limit,
        search: searchTerm,
        status: activeTab,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, currentPage, limit, searchTerm, setLoading, activeTab]);

  useEffect(() => {
    getEventsDetailsData();
  }, [getEventsDetailsData]);

  const fetchCustomers = useCallback(() => {
    dispatch(fetchEvents({ id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (addModal) {
      fetchCustomers();
    }
  }, [addModal, fetchCustomers]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleAddClick = () => {
    setAddModal(true);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
    // setSelectedCustomer(null); // Reset selected customer when modal closes
    setIsWhatsappConnected(false); // Reset WhatsApp connection state
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
  };

  const campaignValidation = Yup.object().shape({
    customer_id: Yup.string().required("Customer is required"),
    name: Yup.string().required("Campaign name is required"),
    sendOption: Yup.string().required("Send option is required"),
  });

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name}`,
    isWhatsappConnected: customer.whatsapp_client?.is_active || false, // Assuming `whatsapp_client.is_active` exists
  }));

  const handleCustomerSelect = (option, setFieldValue) => {
    console.log("OPTION : ", option);
    setSelectedCustomer(option);
    setIsWhatsappConnected(option.isWhatsappConnected);
    if (!option.isWhatsappConnected) {
      toggleAddModal();
      setShowWhatsappModal(true); // Show WhatsApp connection modal if not connected
    }
    setFieldValue("customer_id", option.value);
  };

  const handleConnectWhatsapp = () => {
    console.log("Selected Customer : ", selectedCustomer);
    navigate(`/customers/${selectedCustomer.value}`);
    setShowWhatsappModal(false); // Close the modal
  };

  const getColumnsForActiveTab = () => {
    const commonColumns = [
      {
        label: "Campaign Name",
        dataKey: "name",
      },
      {
        label: "Customer Name",
        dataKey: "client_event.name",
      },
      {
        label: "Customer Number",
        dataKey: "client_event.contact_number",
      },
      {
        label: "Send Type",
        dataKey: "template_type",
      },
      {
        label: "Status",
        dataKey: "status",
      },
    ];

    const dynamicColumns = [];

    switch (activeTab) {
      case "scheduled":
        dynamicColumns.push({
          label: "Scheduled Date",
          dataKey: "scheduled_date",
          format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
        });
        break;
      case "completed":
      case "in-process":
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      case "failed":
        dynamicColumns.push({
          label: "Failed Reason",
          dataKey: "failed_reason",
        });
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      default:
        break;
    }

    const remainingColumns = [
      {
        label: "Credits Used",
        dataKey: "total_credits",
      },
      {
        label: "Created At",
        dataKey: "created_at",
        format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
      },
    ];

    if (activeTab !== "pending" && activeTab !== "drafted") {
      remainingColumns.unshift({
        label: "Recipients",
        dataKey: "client_guests_not_test_count",
        // format: (value) =>
        //   Array.isArray(value)
        //     ? value.filter((guest) => !guest.is_test).length
        //     : 0,
      });
    }

    return [...commonColumns, ...dynamicColumns, ...remainingColumns];
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handleSendMessageClick = (id) => {
    // Logic for sending messages (currently disabled)
  };

  const handleDeleteCampaignClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCampaign({ id: selectedCampaign.id }));
      setSnackbarMessage("Campaign deleted successfully");
      setSnackbarVariant("success");
      setShowSnackbar(true);
      getEventsDetailsData(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  const ActionMenu = ({ campaing }) => {
    return (
      <Dropdown drop="up" className="position-static">
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          {(campaing.status === "pending" || campaing.status === "drafted") && (
            <Dropdown.Item
              eventKey="1"
              onClick={() => handleSendMessageClick(campaing.id)}
            >
              Send Message
            </Dropdown.Item>
          )}
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              navigate(`/customers/view-campaign/${campaing.id}`);
            }}
          >
            View
          </Dropdown.Item>
          {(campaing.status === "pending" || campaing.status === "drafted" || campaing.status === "scheduled") && <Dropdown.Item
            eventKey="3"
            // onClick={() => {
            //   navigate(`/customers/view-campaign/${campaing.id}`);
            // }}
            onClick={() => handleDeleteCampaignClick(campaing)}
          >
            Delete
          </Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
    <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          {snackbarMessage}
        </Toast.Body>
      </Toast>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row className="g-5 mt-0">
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0  text-white">Campaigns List</h3>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="tablecontainer">
              <div className="mb-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleAddClick}>
                  New Campaign
                </Button>
              </div>
              <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs">
                <Tabs
                  defaultActiveKey="all"
                  className="pb-0 table-tabs-items"
                  id="uncontrolled-tab-example"
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="all" title="All"></Tab>
                  <Tab eventKey="pending" title="Pending"></Tab>
                  <Tab eventKey="drafted" title="Drafted"></Tab>
                  <Tab eventKey="scheduled" title="Scheduled"></Tab>
                  <Tab eventKey="in-process" title="In-process"></Tab>
                  <Tab eventKey="completed" title="Completed"></Tab>
                  <Tab eventKey="failed" title="Failed"></Tab>
                </Tabs>
              </div>
               
              <div className="overflow-auto">
                <Table className="text-nowrap text-start">
                  <thead className="table-light">
                    <tr>
                      {getColumnsForActiveTab().map((column) => (
                        <th key={column.dataKey}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{column.label}</span>
                            {/* <img
                              src={SortImage}
                              alt="Sort"
                              style={{ width: "17px", height: "16px" }}
                            /> */}
                          </div>
                        </th>
                      ))}
                      <th>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Action</span>
                          {/* <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          /> */}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaings?.length === 0 ? (
                      <tr>
                        <td className="text-center" colSpan="7">
                          <div className="mt-5 fw-bold fs-5">
                            No data available
                          </div>
                        </td>
                      </tr>
                    ) : (
                      campaings?.map((campaing) => (
                        <tr key={campaing?.id}>
                          {getColumnsForActiveTab().map((column) => (
                            <td
                              key={column.dataKey}
                              className="text-capitalize"
                            >
                              {column.format
                                ? column.format(campaing[column.dataKey])
                                : column.dataKey.includes(".")
                                ? column.dataKey
                                    .split(".")
                                    .reduce((o, i) => (o ? o[i] : ""), campaing)
                                : column.dataKey === "total_credits"
                                ? campaing[column.dataKey] || 0
                                : column.dataKey === "template_type"
                                ? campaing[column.dataKey] === "text"
                                  ? "Only Message"
                                  : "With Invitation Card"
                                : campaing[column.dataKey]}
                            </td>
                          ))}
                          <td>
                            {/* <Dropdown drop="up" className="position-static">
                              <Dropdown.Toggle as={CustomToggle}>
                                <MoreVertical
                                  size="15px"
                                  className="text-muted"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu align={"end"}>
                                {(campaing.status === "pending" ||
                                  campaing.status === "drafted") && (
                                  <Dropdown.Item
                                    eventKey="1"
                                    onClick={() =>
                                      handleSendMessageClick(campaing.id)
                                    }
                                  >
                                    Send Message
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                  eventKey="2"
                                  onClick={() => {
                                    navigate(
                                      `/customers/view-campaign/${campaing.id}`
                                    );
                                  }}
                                >
                                  View
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                            <ActionMenu
                              campaing={campaing}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>

                {totalPages > 1 ? (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Campaign Modal */}
      <Modal
        show={addModal}
        onHide={() => {
          setSelectedCustomer(null);
          toggleAddModal();
        }}
        centered
      >
        <ModalHeader closeButton>
          <h3 className="text-primary mb-0">New Campaign</h3>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              customer_id: "",
              name: "",
              sendOption: "invitation",
            }}
            validationSchema={campaignValidation}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors }
            ) => {
              setIsSubmitting(true)
              const formData = new FormData();
              formData.append("client_id", values.customer_id);
              formData.append("_method", "POST");
              formData.append("name", values.name);

              if (values.sendOption === "message") {
                formData.append("template_type", "text");
              }

              dispatch(addCampaign(formData))
                .then((res) => {
                  if (res.payload.success) {
                    dispatch(
                      fetchAllCampaigns({
                        offset: currentPage,
                        limit,
                        search: searchTerm,
                        status: activeTab,
                      })
                    );
                    resetForm();
                    setSelectedCustomer(null);
                    toggleAddModal();
                    navigate(
                      `/customers/${values.customer_id}/add-campaign/${res.payload.data.id}`
                    );
                  } else {
                    setErrors(res.payload.data);
                  }
                })
                .catch((error) => {
                  console.error("Failed to add campaign:", error);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              // isSubmitting,
              submitForm,
              values,
              setFieldValue,
            }) => (
              <>
                <Form.Group controlId="customerSelect">
                  <Form.Label>Select a Customer</Form.Label>
                  <Select
                    options={customerOptions}
                    name="customer_id"
                    value={customerOptions.find(
                      (option) => option.value === values.customer_id
                    )}
                    onChange={(option) =>
                      handleCustomerSelect(option, setFieldValue)
                    }
                    className={`${
                      errors.customer_id && touched.customer_id
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="-- Select a Customer --"
                  />
                  {errors.customer_id && touched.customer_id && (
                    <div className="invalid-feedback">{errors.customer_id}</div>
                  )}
                </Form.Group>
                <br />

                {isWhatsappConnected?
                <> 
                <div className="d-flex gap-3 mb-3">
                  <FormCheck
                    type="radio"
                    label="Send with Document"
                    name="sendOption"
                    id="sendInvitation"
                    value="invitation"
                    checked={values.sendOption === "invitation"}
                    onChange={handleChange}
                    className={`${
                      errors.sendOption && touched.sendOption
                        ? "is-invalid"
                        : ""
                    } `}
                  />
                  <FormCheck
                    type="radio"
                    label="Only Message"
                    name="sendOption"
                    id="sendMessage"
                    value="message"
                    checked={values.sendOption === "message"}
                    onChange={handleChange}
                    className={`${
                      errors.sendOption && touched.sendOption
                        ? "is-invalid"
                        : ""
                    }`}
                  />

                  {errors.sendOption && touched.sendOption ? (
                    <div className="invalid-feedback">{errors.sendOption}</div>
                  ) : null}
                </div>
                <Field
                  name="name"
                  type="text"
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                  placeholder="Enter campaign name"
                  onChange={handleChange}
                  disabled={!isWhatsappConnected} // Disable the input if WhatsApp is not connected
                />
                {errors.name && touched.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
                <br />
                </> : null}
                <ModalFooter className="d-flex justify-content-end">
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      variant="primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isWhatsappConnected} // Disable the Add button if WhatsApp is not connected
                    >
                      {isSubmitting? "Adding...": "Add"}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setSelectedCustomer();
                        toggleAddModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      {/* WhatsApp Connection Modal */}
      <Modal
        show={showWhatsappModal}
        onHide={() => setShowWhatsappModal(false)}
        centered
      >
        <ModalHeader closeButton>
          <h3 className="text-danger mb-0">WhatsApp Not Connected</h3>
        </ModalHeader>
        <ModalBody>
          <p>
            The selected customer does not have WhatsApp connected. Please
            connect WhatsApp before adding a campaign.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleConnectWhatsapp}>
            Connect WhatsApp
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowWhatsappModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this campaign?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
