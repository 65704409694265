import React, { useState } from "react";
import CommonLayout from "../../layouts/master/index";
// import { Button } from "react-bootstrap";
import List from "./List";
// import Add from "./Add";
import Edit from "./Edit";
import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

export default function EventDetails() {
  // const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentResellerId, setCurrentResellerId] = useState(null);
  const [
    resellers,
    // setResellers
  ] = useState([]);
  const { id } = useParams();
  // console.log('Params : ', params);
  const fetchResellers = async () => {};

  // const handleAddReseller = (reseller) => {
  //   setShowAddModal(false);
  // };
  // const events = useSelector((state) => state.events.events);


  // const handleEditReseller = (reseller) => {
  //     setShowEditModal(false);
  // };

  // const handleOpenModal = () => {
  //   setShowAddModal(true);
  // };

  // const handleCloseModal = () => {
  //   setShowAddModal(false);
  // };

  const handleDeleteReseller = (id) => {};
  return (
    <>
      <CommonLayout>
        {/* <div style={{ marginLeft: "1400px", marginTop: "20px" }}>
          <Button
            variant="primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Reseller
          </Button>
        </div> */}
        <div>
          <List
            client_id={id}
            resellers={resellers}
            // handleOpenModal={handleOpenModal}
            editHandler={(id) => {
              setCurrentResellerId(id);
              setShowEditModal(true);
            }}
            deleteHandler={handleDeleteReseller}
            // handleClose={() => setShowAddModal(false)}
          />
          {/* <Add
            show={showAddModal}
            handleClose={handleCloseModal}
            saveReseller={handleAddReseller}
            id={id}
          /> */}
          {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              resellerId={currentResellerId}
              updateResellersList={fetchResellers}
            />
          )}
        </div>
      </CommonLayout>
    </>
  );
}
