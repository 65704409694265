import React from "react";
import CommonLayout from "../../layouts/master/index";
// import { Button } from "react-bootstrap";
import List from "./List";
// import Add from "./Add";
// import Edit from "./Edit";
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

export default function AllCampaigns() {
  // const [showAddModal, setShowAddModal] = useState(false);
  // const [showEditModal, setShowEditModal] = useState(false)
  // const [currentResellerId, setCurrentResellerId] = useState(null);
 
  // const { id } = useParams();


  // const handleDeleteReseller = (id) => {};
  return (
    <>
      <CommonLayout>
        
        <div>
          <List />
          {/* {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              resellerId={currentResellerId}
              updateResellersList={fetchResellers}
            />
          )} */}
        </div>
      </CommonLayout>
    </>
  );
}
