import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Toast,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { Button as BTN } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InsertCsv from "./steps/InsertCsv";
import InsertPdf from "./steps/InsertPdf";
import CommonLayout from "../../layouts/master/index";
import InsertMessage from "./steps/InsertMessage";
import EditPdf from "./steps/EditPdf";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignById, updateEvent } from "../../../store/eventsSlice";
import ProgressBar from "./progressBar/ProgressBar";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";
import { ArrowLeft, Save, Send } from "react-feather";
// import axios from "axios";

const getSteps = (templateType) => {
  if (templateType === "text") {
    return [
      {
        id: "whatsapp-details",
        title: "Import Numbers",
        subtitle: "Enter WhatsApp Numbers",
        component: InsertCsv,
      },
      {
        id: "message-details",
        title: "Add Message",
        subtitle: "Add Message",
        component: InsertMessage,
      },
    ];
  } else {
    return [
      {
        id: "whatsapp-details",
        title: "Import Numbers",
        subtitle: "Enter WhatsApp Numbers",
        component: InsertCsv,
      },
      {
        id: "import-file",
        title: "Import File",
        subtitle: "Add PDF or Image",
        component: InsertPdf,
      },
      {
        id: "send-invitation",
        title: "Send Invitation",
        subtitle: "Send Invitation",
        component: EditPdf,
      },
      {
        id: "message-details",
        title: "Add Message",
        subtitle: "Add Message",
        component: InsertMessage,
      },
    ];
  }
};

const WizardStep = ({
  step,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  setTotalPages,
  totalPages,
  setFileType,
  fileType,
  setFile,
  file,
  setPagesUrl,
  pagesUrl,
  messageData,
  setMessageData,
  tableData,
  setTableData,
  csvKeys,
  setCsvKeys,
  setPos,
  pos,
  validateStep,
  errors,
  manualText,
  setManualText,
  textAreaData,
  setTextAreaData,
  validation,
  currentStep,
  setFileName,
  fileName,
  setSelectedImage,
  selectedImage,
  setOriginalFile,
  originalFile,
  campaingDetails,
  isTestOpen,
  setIsTestOpen,
  handleTestClick,
  fileUrl,
  setFileUrl,
  templateType,
  setSnackbarMessage,
  setSnackbarVariant,
  setShowSnackbar,
}) => {
  const StepComponent = step.component;
  return (
    <div>
      <StepComponent
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        setFormError={setFormError}
        inputdata={inputdata}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setFileType={setFileType}
        fileType={fileType}
        setFile={setFile}
        file={file}
        setPagesUrl={setPagesUrl}
        pagesUrl={pagesUrl}
        messageData={messageData}
        setMessageData={setMessageData}
        tableData={tableData}
        setTableData={setTableData}
        csvKeys={csvKeys}
        setCsvKeys={setCsvKeys}
        setPos={setPos}
        pos={pos}
        validateStep={validateStep}
        errors={errors}
        manualText={manualText}
        setManualText={setManualText}
        textAreaData={textAreaData}
        setTextAreaData={setTextAreaData}
        validation={validation}
        currentStep={currentStep}
        setFileName={setFileName}
        fileName={fileName}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        setOriginalFile={setOriginalFile}
        originalFile={originalFile}
        campaingDetails={campaingDetails}
        isTestOpen={isTestOpen}
        setIsTestOpen={setIsTestOpen}
        handleTestClick={handleTestClick}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        templateType={templateType}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarVariant={setSnackbarVariant}
        setShowSnackbar={setShowSnackbar}
      />
    </div>
  );
};

const EventIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId, id } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialState = {
    schedule: "",
    countryCode: "",
    delaySecond: 5,
    delayFromSecond: 3,
    delayToSecond: 10,
  };

  const campaingDetails = useSelector((state) => state.events.campaingDetails);

  // console.log("cCampaign Data : ", campaingDetails);

  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [fileType, setFileType] = useState("");
  const [messageData, setMessageData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [csvKeys, setCsvKeys] = useState([]);
  const [file, setFile] = useState(null);
  const [pagesUrl, setPagesUrl] = useState([]);
  const [pos, setPos] = useState({});
  const [errors, setErrors] = useState({});
  const [manualText, setManualText] = useState("");
  const [textAreaData, setTextAreaData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalFile, setOriginalFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [showExitModal, setShowExitModal] = useState(false);

  // console.log("Form Data :: ", formData);
  // console.log("form Error :: ", formError);
  // console.log("total Pages :: ", totalPages);
  // console.log("file type :: ", fileType);
  // console.log("table Data :: ", tableData);
  // console.log("csv Keys :: ", formData);
  // console.log("file :: ", file);
  // console.log("pages Url :: ", pagesUrl);
  // console.log("pos :: ", pos);
  // console.log("errors :: ", errors);
  // console.log("manual Text :: ", manualText);
  // console.log("text Area Data :: ", textAreaData);
  // console.log("file Name :: ", fileName);
  // console.log("selected Image :: ", selectedImage);
  // console.log("original File :: ", originalFile);
  const [templateType, setTemplateType] = useState("");

  const steps = getSteps(templateType);

  const inputdata = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({
      ...formError,
      [e.target.name]: validation({ [e.target.name]: e.target.value })[
        e.target.name
      ],
    });
  };
  const { setLoading } = useLoader();

  const getCampaignDetail = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchCampaignById({
        // offset: currentPage,
        // limit,
        // search: searchTerm,
        id: id,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id, setLoading]);

  useEffect(() => {
    getCampaignDetail();
  }, [step, getCampaignDetail]);

  const [isTestOpen, setIsTestOpen] = useState(false);

  const handleTestClick = async () => {
    // console.log("campaingDetails?.customer?.credits : ", tableData?.length);
    if (campaingDetails?.customer?.credits <= tableData?.length) {
      setSnackbarMessage("Not have enough credits.");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
      return;
    }
    setIsTestOpen(true);
  };

  useEffect(() => {
    if (campaingDetails?.status === "drafted") {
      // console.log("Campaign data : ", campaingDetails);
      setFormData({
        schedule: campaingDetails?.scheduler_date
          ? moment(campaingDetails?.scheduler_date).format("YYYY-MM-DDTHH:mm")
          : "",
        countryCode: campaingDetails?.country_code || "",
        delaySecond: parseInt(campaingDetails?.delay_second) || 5,
        delayFromSecond: parseInt(campaingDetails?.delay_second_from) || 3,
        delayToSecond: parseInt(campaingDetails?.delay_second_to) || 10,
      });

      setMessageData(campaingDetails?.message || "");

      // const guests = campaingDetails?.client_guests?.map(guest => ({
      //   name: guest.name,
      //   number: guest.mobile_number,
      //   variables: guest.variables || []
      // }));

      const guests = campaingDetails?.client_guests?.map((guest) => {
        let guestData = {
          name: guest.name,
          number: guest.mobile_number,
        };

        // if (guest?.variables?.length) {
        //   console.log("vhiusdgcfidsagiufg");
        //   guestData.variables = guest.variables;
        // }
        if (guest?.variables && Object.keys(guest.variables).length > 0) {
          Object.keys(guest.variables).forEach((key) => {
            guestData[key] = guest.variables[key];
          });
        }

        return guestData;
      });
      // console.log("chascsajcj GUESTS :::::: ", guests);
      setTableData(guests || []); // Assuming `client_guests` is the list of guests
      // if (campaingDetails) {
      //   console.log("cnjsabckjsa", campaingDetails);
      //   setFileType(
      //     originalFile
      //       ? ""
      //       : campaingDetails?.template_type === "pdf"
      //       ? "application/pdf"
      //       : "image/jpeg" || ""
      //   );
      // }
      setPos(campaingDetails.variable_positions || {});
      setCsvKeys(campaingDetails.variables || []);
      setFileName(fileType === "application/pdf" ? "sample.pdf" : "sample.png");
      // If the template is an image, you might want to load it as `selectedImage`
      // if (campaingDetails.template_type === "image") {
      //   setSelectedImage(campaingDetails.template_url);
      // }
      // if (campaingDetails.template_type === "pdf") {
      //   setOriginalFile(campaingDetails.template_url);
      // }
      setFileUrl(originalFile ? "" : campaingDetails.template_url);

      // const manualTextContent = campaingDetails?.client_guests
      //   ?.map((guest) => {
      //     const variablesString = guest?.variables?.join(",");
      //     return `${guest.name},${guest.mobile_number}${
      //       variablesString ? "," + variablesString : ""
      //     }`;
      //   })
      //   .join("\n");
      const manualTextContent = campaingDetails?.client_guests
        ?.map((guest) => {
          const variables = guest?.variables
            ? Object.values(guest.variables).join(",")
            : "";
          return `${guest.name},${guest.mobile_number}${
            variables ? "," + variables : ""
          }`;
        })
        .join("\n");
      setManualText(manualTextContent);

      // Set textAreaData based on manualText
      setTextAreaData(manualTextContent?.split("\n"));
    } else if (campaingDetails?.status === "pending") {
      setFormData({
        schedule: "",
        countryCode: "",
        delaySecond: 5,
        delayFromSecond: 3,
        delayToSecond: 10,
      });
      setMessageData("");
      setTableData([]);
      setPos({});
      setCsvKeys([]);
      setFileName("");
      setFileUrl("");
      setManualText("");
      setTextAreaData("");
    }
    setFileType(
      originalFile
        ? fileType
        : campaingDetails?.template_type === "pdf"
        ? "application/pdf"
        : "image/jpeg" || ""
    );
    setTemplateType(campaingDetails?.template_type || "");
  }, [campaingDetails, fileType, originalFile]);

  const validation = (values) => {
    let errors = {};
    const regCountryCode = /^[0-9]/;
    // const regDelaySecond = /^[0-9]/;
    if (!values.countryCode) {
      errors.countryCode = "Country Code is Required";
    } else if (!regCountryCode.test(values.countryCode)) {
      errors.countryCode = "Enter Valid Country Code";
    } 
    // else if (
    //   values.countryCode.length !== 2 &&
    //   values.countryCode.length !== 3
    // ) {
    //   errors.countryCode = "Enter Valid Country Code GG";
    // }
    if (values.schedule) {
      const selectedDate = moment(values.schedule);
      const currentDate = moment();

      if (selectedDate.isSame(currentDate, "day")) {
        // Check only hours and minutes
        const selectedTime = selectedDate.format("HH:mm");
        const currentTime = currentDate.format("HH:mm");

        if (selectedTime < currentTime) {
          errors.schedule =
            "Scheduled time cannot be earlier than the current time.";
        }
      } else if (errors?.schedule) {
        delete errors.schedule;
      }
    }
    // if (!values.delaySecond) {
    //   errors.delaySecond = "Please enter valid delay time";
    // } else if (!regDelaySecond.test(parseInt(values.delaySecond))) {
    //   errors.delaySecond = "Please enter valid delay time";
    // } else if (parseInt(values.delaySecond) > 10) {
    //   errors.delaySecond = "Minimum 0 second and maximum 10 second";
    // }
    return errors;
  };

  const validateStep = (step) => {
    let stepErrors = {};

    if (templateType === "text") {
      switch (step) {
        case 0:
          if (!tableData || tableData.length === 0) {
            stepErrors.tableData = "Please import WhatsApp numbers.";
          }
          break;
        case 1:
          if (!messageData) {
            stepErrors.messageData = "Please add a message.";
          }
          break;
        default:
          break;
      }
    } else {
      switch (step) {
        case 0:
          if (!tableData || tableData.length === 0) {
            stepErrors.tableData = "Please import WhatsApp numbers.";
          }
          break;
        case 1:
          if (!file) {
            stepErrors.file = "Please import a PDF or Image.";
          }
          break;
        case 3:
          if (!messageData) {
            stepErrors.messageData = "Please add a message.";
          }
          break;
        default:
          break;
      }
    }

    setErrors(stepErrors);
    if (Object.keys(stepErrors).length > 0) {
      setSnackbarMessage(Object.values(stepErrors)[0]);
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
    return Object.keys(stepErrors).length === 0;
  };

  // const handleNext = () => {
  //   if (validateStep(step)) {
  //     setStep((prevStep) =>
  //       prevStep < steps.length - 1 ? prevStep + 1 : prevStep
  //     );
  //   }
  // };

  const transformGuestList = (guestList) => {
    return guestList.map((guest) => {
      const { name, number: mobile_number, ...variables } = guest;
      // const cleanedNumber = cleanPhoneNumber(
      //   mobile_number,
      //   formData.countryCode
      // );
      return { name, mobile_number, variables };
    });
  };

  // const saveStepData = async () => {
  //   const request = new FormData();

  //   // Step 1: Import Numbers
  //   if (step === 0) {
  //     request.append(
  //       "guest_list",
  //       JSON.stringify(transformGuestList(tableData))
  //     );
  //     request.append("variables", JSON.stringify(csvKeys));
  //   }

  //   // Step 2: Import File
  //   if (step === 1) {
  //     request.append(
  //       "template_url",
  //       fileType === "application/pdf" ? file : originalFile
  //     );
  //     request.append(
  //       "template_type",
  //       fileType === "application/pdf" ? "pdf" : "image"
  //     );
  //   }

  //   // Step 3: Edit PDF
  //   if (step === 2) {
  //     request.append("variable_positions", JSON.stringify(pos));
  //   }

  //   // Step 4: Add Message
  //   if (step === 3) {
  //     request.append("message", messageData);
  //   }

  //   // General data (common for all steps)
  //   request.append("country_code", formData.countryCode || "91");
  //   // request.append("delay_second", formData.delaySecond);
  //   request.append("delay_second_from", formData.delayFromSecond);
  //   request.append("delay_second_to", formData.delayToSecond);
  //   request.append("client_campaign_id", id);
  //   request.append("client_id", clientId);
  //   request.append("is_save", true);
  //   request.append("current_step", step);

  //   if (!formData.schedule) {
  //     request.append("scheduler_date", "");
  //   } else {
  //     request.append("scheduler_date", formData.schedule);
  //   }

  //   try {
  //     setLoading(true);
  //     const response = await dispatch(updateEvent(request));
  //     setLoading(false);

  //     console.log("Reponse ..... ", response);

  //     if (!response.payload.success) {
  //       setSnackbarMessage(response.payload.message);
  //       setSnackbarVariant("danger");
  //       setShowSnackbar(true);
  //       return false;
  //     }
  //     return true;
  //   } catch (error) {
  //     setSnackbarMessage("Failed to save data: " + error.message);
  //     setSnackbarVariant("danger");
  //     setShowSnackbar(true);
  //     setLoading(false);
  //     return false;
  //   }
  // };

  const saveStepData = async () => {
    const request = new FormData();

    // Step 1: Import Numbers
    if (step === 0) {
      request.append(
        "guest_list",
        JSON.stringify(transformGuestList(tableData))
      );
      request.append("variables", JSON.stringify(csvKeys));
      if (templateType === "text") {
        request.append("template_type", "text");
      }
    }

    // Step 2: Import File (only if template_type is not text)
    if (step === 1 && templateType !== "text") {
      request.append(
        "template_url",
        fileType === "application/pdf" ? file : originalFile
      );
      request.append(
        "template_type",
        fileType === "application/pdf" ? "pdf" : "image"
      );
    }

    // Step 3: Edit PDF (only if template_type is not text)
    if (step === 2 && templateType !== "text") {
      request.append("variable_positions", JSON.stringify(pos));
    }

    // Step 4: Add Message (if template_type is text, this will be step 1)
    const messageStep = templateType === "text" ? 1 : 3;
    if (step === messageStep) {
      request.append("message", messageData);
    }

    // General data (common for all steps)
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second_from", formData.delayFromSecond);
    request.append("delay_second_to", formData.delayToSecond);
    request.append("client_campaign_id", id);
    request.append("client_id", clientId);
    request.append("is_save", true);
    request.append("current_step", step);

    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }

    try {
      setLoading(true);
      const response = await dispatch(updateEvent(request));
      setLoading(false);

      // console.log("Response ..... ", response);

      if (!response.payload.success) {
        setSnackbarMessage(response.payload.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        return false;
      }
      return true;
    } catch (error) {
      setSnackbarMessage("Failed to save data: " + error.message);
      setSnackbarVariant("danger");
      setShowSnackbar(true);
      setLoading(false);
      return false;
    }
  };

  const handleNext = async () => {
    if (validateStep(step)) {
      const isSaved = await saveStepData();
      if (isSaved) {
        setStep((prevStep) =>
          prevStep < steps.length - 1 ? prevStep + 1 : prevStep
        );
      }
    }
  };

  const handlePrev = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const handleFinish = async () => {
    setIsSubmitting(true);
    // console.log("Fnish");
    const formErrors = validation(formData);

    console.log("Form Error ff: ", formError);
    setFormError(formErrors);
    console.log("FORM ERROR : ", formData);

    if (Object.keys(formErrors).length > 0) {
      setSnackbarMessage(Object.values(formErrors)[0]);
      setSnackbarVariant("danger");
      setShowSnackbar(true);
      setIsSubmitting(false);
      return;
    }
    console.log("FORM ERROR : ", formError);

    if (!validateStep(step)) {
      setIsSubmitting(false);

      return;
    }

    // const cleanPhoneNumber = (phoneNumber, countryCode) => {
    //   const regex = new RegExp(`^${countryCode}`);
    //   const cleanedNumber = phoneNumber.replace(regex, "").trim();
    //   return cleanedNumber;
    // };

    const transformedGuests = transformGuestList(tableData);

    const request = new FormData();
    request.append(
      "template_url",
      templateType === "text"
        ? ""
        : fileType === "application/pdf"
        ? file
        : originalFile
    );
    request.append(
      "template_type",
      templateType === "text"
        ? "text"
        : fileType === "application/pdf"
        ? "pdf"
        : "image"
    );
    request.append("message", messageData);
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second", formData.delaySecond);
    request.append("delay_second_from", formData.delaySecond);
    request.append("delay_second_to", formData.delaySecond);
    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }
    request.append("variable_positions", JSON.stringify(pos));
    request.append("variables", JSON.stringify(csvKeys));
    request.append("guest_list", JSON.stringify(transformedGuests));
    request.append("client_campaign_id", id);
    request.append("client_id", clientId);

    dispatch(updateEvent(request))
      .then(async (res) => {
        if (res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setIsSubmitting(false);

          // try {
          //   const formData = new FormData();
          //   formData.append("template_url", file); // Attach the PDF file
          //   formData.append("clientId", clientId);
          //   // formData.append("message", messageData);
          //   // formData.append("guests", JSON.stringify(transformedGuests));
          //   formData.append("wsId", "unique_ws_id");
          //   formData.append("campaignId", id);

          //   // await axios.post(`${process.env.REACT_APP_WHATSAPP_API_URL}/send-message`, formData);
          //   navigate(`/customers/view-campaign/${id}`);
          // } catch (error) {
          //   setSnackbarMessage("Failed to send messages: " + error.message);
          //   setSnackbarVariant("danger");
          //   setShowSnackbar(true);
          // }

          navigate(`/customers/view-campaign/${id}`);
        } else {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add event: " + error.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setIsSubmitting(false);
      });
  };
  const handleSave = async () => {
    setIsSubmitting(true);
    if (!validateStep(step)) {
      setIsSubmitting(false);
      return;
    }

    // const cleanPhoneNumber = (phoneNumber, countryCode) => {
    //   const regex = new RegExp(`^${countryCode}`);
    //   const cleanedNumber = phoneNumber.replace(regex, "").trim();
    //   return cleanedNumber;
    // };

    const transformGuestList = (guestList) => {
      return guestList.map((guest) => {
        const { name, number: mobile_number, ...variables } = guest;
        // const cleanedNumber = cleanPhoneNumber(
        //   mobile_number,
        //   formData.countryCode
        // );
        return { name, mobile_number, variables };
      });
    };

    const transformedGuests = transformGuestList(tableData);

    const request = new FormData();

    // console.log("SAVE ::::: ", file);
    request.append(
      "template_url",
      fileType === "application/pdf" ? file : originalFile
    );
    request.append(
      "template_type",
      templateType === "text"
        ? "text"
        : fileType === "application/pdf"
        ? "pdf"
        : "image"
    );
    request.append("message", messageData);
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second", formData.delaySecond);
    request.append("delay_second_from", formData.delayFromSecond);
    request.append("delay_second_to", formData.delayToSecond);
    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }
    request.append("variable_positions", JSON.stringify(pos));
    request.append("variables", JSON.stringify(csvKeys));
    request.append("guest_list", JSON.stringify(transformedGuests));
    request.append("client_campaign_id", id);
    request.append("client_id", clientId);
    request.append("is_save", true);

    dispatch(updateEvent(request))
      .then(async (res) => {
        if (res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setIsSubmitting(false);

          // try {
          //   const formData = new FormData();
          //   formData.append("template_url", file); // Attach the PDF file
          //   formData.append("clientId", clientId);
          //   // formData.append("message", messageData);
          //   // formData.append("guests", JSON.stringify(transformedGuests));
          //   formData.append("wsId", "unique_ws_id");
          //   formData.append("campaignId", id);

          //   // await axios.post(`${process.env.REACT_APP_WHATSAPP_API_URL}/send-message`, formData);
          //   navigate(`/customers/view-campaign/${id}`);
          // } catch (error) {
          //   setSnackbarMessage("Failed to send messages: " + error.message);
          //   setSnackbarVariant("danger");
          //   setShowSnackbar(true);
          // }

          navigate(`/customers/${clientId}`);
        } else {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add event: " + error.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setIsSubmitting(false);
      });
  };

  const handleBackClick = () => {
    setShowExitModal(true); // Show the exit confirmation modal
  };

  const handleConfirmExit = () => {
    navigate(`/customers/${clientId}`); // Redirect to customers page
  };

  const handleCancelExit = () => {
    setShowExitModal(false); // Close the exit confirmation modal
  };

  return (
    <CommonLayout>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              {/* <div className="d-flex justify-content-between align-items-center">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 text-white">New Campaign</h3>
                </div>
              </div> */}
              <div className="d-flex align-items-center gap-2 primary-pg-title">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                  onClick={() => handleBackClick()}
                >
                  <ArrowLeft
                    size={24}
                    color="#FFFFFF"
                    weight="bold"
                    className="cursor-pointer"
                  />
                </div>

                <h3 className="mb-0 fw-semibold text-white pg-title fw-medium">
                  Campaign Details
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="tablecontainer d-flex flex-column justify-content-between position-relative tb-custom-height">
              <div className="position-relative">
                <ProgressBar steps={steps} currentStep={step} />
                <WizardStep
                  step={steps[step]}
                  currentStep={step}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  setFormError={setFormError}
                  inputdata={inputdata}
                  setTotalPages={setTotalPages}
                  totalPages={totalPages}
                  setFileType={setFileType}
                  fileType={fileType}
                  setFile={setFile}
                  file={file}
                  setPagesUrl={setPagesUrl}
                  pagesUrl={pagesUrl}
                  messageData={messageData}
                  setMessageData={setMessageData}
                  tableData={tableData}
                  setTableData={setTableData}
                  csvKeys={csvKeys}
                  setCsvKeys={setCsvKeys}
                  setPos={setPos}
                  pos={pos}
                  validateStep={validateStep}
                  errors={errors}
                  manualText={manualText}
                  setManualText={setManualText}
                  textAreaData={textAreaData}
                  setTextAreaData={setTextAreaData}
                  validation={validation}
                  setFileName={setFileName}
                  fileName={fileName}
                  setSelectedImage={setSelectedImage}
                  selectedImage={selectedImage}
                  setOriginalFile={setOriginalFile}
                  originalFile={originalFile}
                  campaingDetails={campaingDetails}
                  isTestOpen={isTestOpen}
                  setIsTestOpen={setIsTestOpen}
                  handleTestClick={handleTestClick}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  templateType={templateType}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarVariant={setSnackbarVariant}
                  setShowSnackbar={setShowSnackbar}
                />
              </div>

              <div
                className={`d-flex ${
                  step > 0 ? "justify-content-between" : "justify-content-end"
                } pt-5 mt-5`}
              >
                {step !== 0 && (
                  <Button
                    variant="secondary"
                    onClick={handlePrev}
                    disabled={step < 1}
                  >
                    Back
                  </Button>
                )}
                {step < steps.length - 1 && (
                  <Button variant="primary" onClick={() => handleNext()}>
                    Next
                  </Button>
                )}
                {step === steps.length - 1 && (
                  <div className="d-flex gap-3">
                    {/* <button type="button" class="btn btn-outline-primary">Test</button> */}

                    <BTN
                      type="button"
                      outline
                      color="primary"
                      className="btn-prev ms-1 d-flex gap-1 align-items-center"
                      onClick={() => handleTestClick()}
                      disabled={isSubmitting}
                    >
                      <Send
                        size={14}
                        className="align-middle me-sm-25 me-0"
                      ></Send>
                      <span className="align-middle d-sm-inline-block d-none">
                        Test
                      </span>
                    </BTN>

                    <BTN
                      type="button"
                      outline
                      color="primary"
                      onClick={handleSave}
                      disabled={isSubmitting}
                      className="d-flex gap-1 align-items-center"
                    >
                      <Save
                        size={14}
                        className="align-middle me-sm-25 me-0"
                      ></Save>
                      {isSubmitting ? "Saving..." : "Save"}
                    </BTN>

                    <Button
                      variant="primary"
                      onClick={handleFinish}
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? "Sending..."
                        : formData?.schedule
                        ? "Send Scheduled"
                        : "Send"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showExitModal} onHide={handleCancelExit} centered>
        <ModalHeader closeButton>
          <h3 className="text-danger mb-0">Exit Confirmation</h3>
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to exit? All unsaved changes will be lost.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button variant="secondary" onClick={handleCancelExit}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmExit}>
            Exit
          </Button>
        </ModalFooter>
      </Modal>
    </CommonLayout>
  );
};

const CheckEvent = () => {
  const { state } = useLocation();
  return state && state.connectionId ? <EventIndex /> : <EventIndex />;
};

export default CheckEvent;
